import React from 'react'
import { Avatar } from '../..'
import classnames from 'classnames'
import TextArea from 'react-textarea-autosize'
import { DirectMessageButton } from './DirectMessageButton'
import { PrequalSwitch } from '../PrequalSwitch/PrequalSwitch'

import styles from './QuestionSingle.module.scss'

interface QuestionSingleProps {
  avatarUri?: string
  className?: string
  inputValue: string
  isFocused: boolean
  isLoading: boolean
  name?: string
  onBlur: (e) => void
  onChange: (e) => void
  onFocus: (e) => void
  onSubmit: (e) => void
  onTextAreaClick: (e) => void
  placeholder?: string
  showPrequalSwitchUnderSingleQuestion: boolean
}

const QuestionSingle = ({
  className,
  onChange,
  onSubmit,
  inputValue,
  name,
  placeholder,
  avatarUri,
  isLoading,
  isFocused,
  showPrequalSwitchUnderSingleQuestion,
  onBlur,
  onFocus,
  onTextAreaClick
}: QuestionSingleProps) => {
  const [hasSentDM, setHasSentDM] = React.useState(false)

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onSubmit(e)
    setHasSentDM(true)
  }

  return (
    <div>
      <div
        data-qa='questionSingle'
        className={classnames(className, styles.question, avatarUri && styles.withAvatar, isFocused && styles.focused)}
      >
        <TextArea
          className={styles.textarea}
          value={inputValue}
          name={name}
          placeholder={placeholder}
          aria-label={`dm ${inputValue}`}
          onChange={onChange}
          onClick={onTextAreaClick}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {avatarUri && <Avatar className={styles.avatar} src={avatarUri} />}
        <DirectMessageButton onClick={handleSubmit} loading={isLoading} disabled={!inputValue && !placeholder} />
      </div>
      {showPrequalSwitchUnderSingleQuestion && <PrequalSwitch hasSentDM={hasSentDM} setHasSentDM={setHasSentDM} />}
    </div>
  )
}

export default QuestionSingle
