import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl, defineMessages } from 'react-intl'
import { useGetClientId } from '../../../hooks/useGetClientId'
import { useTrackingContext } from '../../../providers/tracking'
import { sendPrequalRequestForClient } from '../../../actions/affordability'
import { FormControl, FormHelperText, Switch } from '@homebotapp/hb-react-component-catalog'
import { useEffectOncePerSessionConditionally } from '../../../hooks/useEffectOncePerSessionConditionally'
import { selectCustomerIsInsuranceAgent, selectIsSoloRea } from '../../../store/selectors/customerProfile'

export const MSG = defineMessages({
  includePrequalRequest: {
    id: 'PrequalCheckbox.prequal',
    defaultMessage: 'Include a pre-qual request'
  },
  getPrequalified: {
    id: 'PrequalCheckbox.getPrequalified',
    defaultMessage: 'Get pre-qualified'
  },
  readyToFindOutWhatICanBuy: {
    id: 'PrequalCheckbox.readyToFindOutWhatICanBuy',
    defaultMessage: 'I am ready to find out what I can buy'
  },
  noCreditPull: {
    id: 'PrequalCheckbox.noCreditPull',
    defaultMessage: 'Check affordability with no credit impact'
  }
})

export interface PrequalSwitchProps {
  hasSentDM: boolean
  setHasSentDM: (hasSentDM: boolean) => void
}

export const PrequalSwitch = ({ hasSentDM, setHasSentDM }: PrequalSwitchProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const clientId = useGetClientId()
  const [checked, setChecked] = useState(true)
  const { experiment, trackingClient } = useTrackingContext()
  experiment?.exposure('pre-qual-cta-copy')
  const variant = experiment?.variant('pre-qual-cta-copy')
  const isSoloRea = useSelector(selectIsSoloRea)
  const isInsuranceAgent = useSelector(selectCustomerIsInsuranceAgent)

  useEffect(() => {
    if (!checked && hasSentDM) {
      setHasSentDM(false) // reset the hasSentDM state so the request doesn't automatically fire if a user checks the prequal switch before sending another DM
    }
  }, [hasSentDM])

  useEffectOncePerSessionConditionally(
    () => {
      dispatch(sendPrequalRequestForClient(trackingClient))
    },
    'prequalRequestSent',
    checked && hasSentDM
  )

  if (isSoloRea || isInsuranceAgent) {
    return null
  }

  return (
    <FormControl ml={2} display='flex' justifyContent='flex-start' flexDirection='column' alignItems='flex-start'>
      <Switch
        mb={0}
        mt={1}
        size='sm'
        name='prequal'
        isChecked={checked}
        onChange={() => setChecked(!checked)}
        tracking={{ guid: 'iyxRnCeXymFWvbfBSnjsU6', ui_context: 'Prequal Checkbox', descriptor: 'Get pre-qualified' }}
      >
        {intl.formatMessage(
          variant?.value === 'i-am-ready-to-find-out-what-i-can-buy'
            ? MSG.readyToFindOutWhatICanBuy
            : variant?.value === 'include-a-prequal-request'
            ? MSG.includePrequalRequest
            : MSG.getPrequalified
        )}
      </Switch>
      <FormHelperText ml={9} mt={0} textAlign='left' fontSize='small'>
        {intl.formatMessage(MSG.noCreditPull)}
      </FormHelperText>
    </FormControl>
  )
}
