import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useTheme,
  useToast
} from '@homebotapp/hb-react-component-catalog'
import { useEffect, useMemo, useState } from 'react'
import { AppLayout } from '../../App/Layout/AppLayout'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveNavigationTab } from '../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../types/navigation'
import { ListingGalleryTile } from '../../Components/homeSearch/ListingGalleryTile/ListingGalleryTile'
import isEmpty from 'lodash/isEmpty'
import openDoor from '../../assets/homes/img/openDoor.svg'
import recentPriceDrop from '../../assets/homes/img/priceDrop.svg'
import savedSearch from '../../assets/homes/img/savedSearch.svg'
import favorites from '../../assets/homes/img/favorites.svg'
import recentlySold from '../../assets/homes/img/recentlySold.svg'
import { defineMessages, useIntl } from 'react-intl'
import history from '../../history'
import { GoogleAutoComplete } from '../../Components/homeSearch/GoogleAutoComplete/GoogleAutoComplete'
import { useRecoilState } from 'recoil'
import {
  CurrentSelectedLocation,
  currentSelectedLocation
} from '../../Components/homeSearch/GoogleAutoComplete/state/currentSelectedLocation'
import { useGetUserFeeds } from '../../hooks/gallery/useGetUserFeeds'
import { ClientEventName, ClientEventSource, FeedBase, FeedType } from '../../api/gqlaxy/gql/generated/graphql'
import { Feed } from '../../Components/homeSearch/Feed/Feed'
import { RootState } from '../../types/rootState'
import { selectNativeAccessFeature } from '../../store/selectors/customerProfile'
import useViewport from '../../hooks/useViewport'
import { DownloadMobileAppPromptCard } from '../../Components/homeSearch/ListingDetails/DownloadMobileAppPromptCard/Desktop/DownloadMobileAppPromptCard'
import { MobileDownloadMobileAppPromptCard } from '../../Components/homeSearch/ListingDetails/DownloadMobileAppPromptCard/Mobile/MobileDownloadMobileAppPromptCard'
import { HomeSearchFooter } from '../../Components/homeSearch/HomeSearchFooter/HomeSearchFooter'
import { selectClientFirstHome, selectClientHomes } from '../../store/selectors/client'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useTrackingContext } from '../../providers/tracking'
import { hasCustomerToken, isAuthTokenSupported } from '../../auth'
import { fetchProfile } from '../../actions/customerProfile'
import { fetchClientData, fetchClientHomes, setCurrentClient } from '../../actions/client'
import { addToast } from '../../actions/toasts'
import { NoPreviewModeModal } from '../../Components/NoPreviewModeModal/NoPreviewModeModal'
import { MarketRecordZipCode } from '../../types/marketRecords'
import { fetchBuyerInfoData } from '../../actions'
import { selectMarketInterestZipcodes } from '../../store/selectors/buyerInfo'
import { fetchMarketRecordsDetail } from '../../actions/marketRecords'
import { getBuyerInfoData } from '../../api/mikasa/requests'
import { useLocation } from 'react-router'
import { SEARCH_PATH_HELPERS } from '../../constants/navigation'
import { useChangeFeedsLocation } from '../../hooks/gallery/useChangeFeedsLocation'
import topographicalMapDark from '../../assets/homes/img/topo-bg-wide.svg'
import topographicalMapLight from '../../assets/homes/img/topo-bg-wide-light.svg'
import { useEffectOncePerSessionConditionally } from '../../hooks'
import { useCreateClientEventOnView } from '../../hooks/useCreateClientEventOnView'
import { ExperimentClient } from '@amplitude/experiment-js-client'
import { useGetClientId } from '../../hooks/useGetClientId'

interface Tile {
  icon: JSX.Element
  label: string
  type?: string
  onClick?: () => void
}

const EXPERIMENT_SORT_ORDERS = {
  'pd-rsow-oh-sc-ah': [
    FeedType.RecentPriceDrop,
    FeedType.RecentlySold,
    FeedType.OpenHouses,
    FeedType.Concessions,
    FeedType.Assumables
  ],
  'rsow-oh-pd-sc-ah': [
    FeedType.RecentlySold,
    FeedType.OpenHouses,
    FeedType.RecentPriceDrop,
    FeedType.Concessions,
    FeedType.Assumables
  ],
  'sc-pd-rsow-oh-ah': [
    FeedType.Concessions,
    FeedType.RecentPriceDrop,
    FeedType.RecentlySold,
    FeedType.OpenHouses,
    FeedType.Assumables
  ]
} as const

export const MSG = defineMessages({
  for: {
    id: 'Gallery.for',
    defaultMessage: 'For'
  },
  concessions: {
    id: 'Gallery.concessions',
    defaultMessage: 'Seller Concessions'
  },
  concessionsDescription: {
    id: 'Gallery.concessionsDescription',
    defaultMessage: 'Sellers cover part of your expenses'
  },
  recentPriceDrop: {
    id: 'Gallery.recentPriceDrop',
    defaultMessage: 'Price Drops'
  },
  recentPriceDropDescription: {
    id: 'Gallery.recentPriceDropDescription',
    defaultMessage: 'See places with a reduced asking price'
  },
  assumables: {
    id: 'Gallery.assumables',
    defaultMessage: 'Assumable Loans'
  },
  assumablesDescription: {
    id: 'Gallery.assumablesDescription',
    defaultMessage:
      "Ask your lender if you qualify for an assumable mortgage. You may be able to save by taking over the seller's low-rate loan!"
  },
  openHouses: {
    id: 'Gallery.openHouses',
    defaultMessage: 'Open Houses'
  },
  openHousesDescription: {
    id: 'Gallery.openHousesDescription',
    defaultMessage: 'See homes in person'
  },
  savedSearch: {
    id: 'Gallery.savedSearch',
    defaultMessage: 'Saved Search'
  },
  favorites: {
    id: 'Gallery.favorites',
    defaultMessage: 'Favorites'
  },
  recentlySold: {
    id: 'Gallery.recentlySold',
    defaultMessage: 'Recently Sold'
  },
  recentlySoldDescription: {
    id: 'Gallery.recentlySoldDescription',
    defaultMessage: 'Explore to see what the market is like'
  },
  yourStuff: {
    id: 'Gallery.yourStuff',
    defaultMessage: 'Your Stuff'
  },
  localGallery: {
    id: 'Gallery.localGallery',
    defaultMessage: 'Local Gallery'
  },
  forYou: {
    id: 'Gallery.forYou',
    defaultMessage: 'For You'
  },
  help: {
    id: 'Gallery.help',
    defaultMessage: 'Let’s find your next home'
  },
  localGalleries: {
    id: 'Gallery.localGalleries',
    defaultMessage: 'Local Galleries'
  },
  changeLocation: {
    id: 'Gallery.changeLocation',
    defaultMessage: 'Change Location'
  },
  failedChangeLocation: {
    id: 'Gallery.failedChangeLocation',
    defaultMessage: 'Failed to change feed location'
  },
  errorCreatingFeeds: {
    id: 'Gallery.errorCreatingFeeds',
    defaultMessage: 'Error creating feeds'
  }
})

const LocalGalleryIcon = {
  recentPriceDrop: recentPriceDrop,
  recentlySold: recentlySold,
  assumables: recentPriceDrop,
  concessions: recentPriceDrop,
  openHouses: openDoor
}

export const ForYouContent = ({ match }) => {
  const intl = useIntl()
  const toast = useToast()
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isMediumOrAbove } = useViewport()
  const isImpersonating = hasCustomerToken()
  const { customerProfileId } = match.params
  const homes = useSelector(selectClientHomes)
  const { experiment, trackingClient } = useTrackingContext()
  const home = useSelector(selectClientFirstHome)
  const clientZipcodes = useSelector(selectMarketInterestZipcodes)
  const clientId = useGetClientId()
  const [loadingMarketInterests, setLoadingMarketInterests] = useState(true)
  const [showGooglePlaceSelector, setShowGooglePlaceSelector] = useState(false)
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)
  const [favoriteMarkets, setFavoriteMarkets] = useState([] as MarketRecordZipCode[])
  const [currentLocation, setCurrentLocation] = useRecoilState(currentSelectedLocation)
  const { mutateAsync: changeFeedsLocation, isLoading: changingFeedsLocation } = useChangeFeedsLocation()

  const createViewClientEvent = useCreateClientEventOnView({
    eventName: ClientEventName.View,
    eventSource: ClientEventSource.ForYou
  })
  createViewClientEvent()

  const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(!isAuthTokenSupported())
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY
  })
  const hasChangeLocationQs = location.search.includes('changeLocation')

  const {
    data,
    isFetching,
    refetch: refreshFeeds
  } = useGetUserFeeds(
    { googlePlaceId: currentLocation?.placeId ?? placePredictions?.[0]?.place_id, webUserId: clientId },
    { enabled: isAuthTokenSupported() && !!placePredictions?.[0] }
  )

  useEffect(() => {
    if (clientZipcodes) {
      dispatch(fetchMarketRecordsDetail(clientZipcodes))
    }
  }, [clientZipcodes, dispatch])

  const loadMarketInterests = async () => {
    try {
      const response = await getBuyerInfoData(clientId)
      setFavoriteMarkets(response?.data?.data?.marketInterests)
    } catch {
    } finally {
      // Delay finish loading to allow setting the favorite markets
      setTimeout(() => {
        setLoadingMarketInterests(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (!isAuthTokenSupported()) {
      setLoadingMarketInterests(false)
      return setShowNotAuthorizedModal(true)
    }

    loadMarketInterests()
    dispatch(fetchBuyerInfoData(clientId))
    if (customerProfileId) {
      dispatch(fetchProfile(customerProfileId))
    }
  }, [dispatch, customerProfileId])

  useEffect(() => {
    if (clientId) {
      dispatch(setCurrentClient(clientId))
      dispatch(fetchClientData(clientId, customerProfileId))
      dispatch(fetchClientHomes(clientId))
    }
  }, [dispatch, clientId, customerProfileId])

  // Helper function to determine input and types
  const getInputAndTypes = ({
    favoriteMarkets,
    home,
    homes
  }: {
    favoriteMarkets: MarketRecordZipCode[]
    home: any
    homes: any[]
  }) => {
    if (!isEmpty(favoriteMarkets)) {
      return { input: favoriteMarkets[0].zipcode, types: ['postal_code'] }
    }
    if (home?.address) {
      return { input: `${home.address.street}, ${home.address.zip}` }
    }
    if (homes?.[0]?.address) {
      return { input: homes[0].address.zip, types: ['postal_code'] }
    }
    return { input: 'Denver, CO' }
  }

  useEffect(() => {
    const noPredictions = !placePredictions?.[0]
    const { input, types } = getInputAndTypes({ favoriteMarkets, home, homes })

    if ((!isEmpty(favoriteMarkets) || home || homes) && noPredictions && !loadingMarketInterests) {
      getPlacePredictions({
        input,
        componentRestrictions: { country: 'us' },
        ...(types && { types })
      })
    } else if (
      !isFetching &&
      !isPlacePredictionsLoading &&
      noPredictions &&
      data?.feeds?.length &&
      !loadingMarketInterests
    ) {
      getPlacePredictions({
        input: data.feeds[0]?.location?.name || '',
        componentRestrictions: { country: 'us' }
      })
    }
  }, [
    home,
    data?.feeds,
    favoriteMarkets,
    isFetching,
    isPlacePredictionsLoading,
    placePredictions,
    loadingMarketInterests
  ])

  useEffect(() => {
    if (!isFetching && data?.feeds?.length) {
      // Set the current location to the first feed location
      setCurrentLocation(data?.feeds[0]?.location as unknown as CurrentSelectedLocation)
    }
  }, [data?.feeds, isFetching])

  const galleryTiles = useMemo(() => {
    const tiles = data?.feeds
      ?.filter(feed => MSG[feed?.feedType])
      .map(feed => {
        return {
          icon: (
            <Image
              h={4}
              w={4}
              loading='lazy'
              src={LocalGalleryIcon[feed?.feedType as string]}
              alt={feed?.feedType as string}
            />
          ),
          label: intl.formatMessage(MSG[feed?.feedType as string]),
          type: feed?.feedType,
          onClick: () => {
            trackingClient?.linkInteraction({
              ui_context: 'Gallery_Feed',
              descriptor: `Clicked on Feed ${feed?.feedType}`,
              action: 'Clicked',
              guid: `3GtcZMbe9HLD7YUKKiAyjg_${feed?.feedType}`
            })
            history.push(SEARCH_PATH_HELPERS.gallery.buildPath(customerProfileId, feed?.id, feed?.feedType, clientId))
          }
        }
      }) as Tile[]
    return tiles?.filter((tile, index, self) => self.findIndex(t => t?.type === tile?.type) === index)
  }, [data?.feeds, clientId])

  useEffect(() => {
    dispatch(setActiveNavigationTab(NAVIGATION_TAB_NAMES.FORYOU))
  }, [dispatch])

  const onShowGooglePlaceSelector = () => {
    if (isImpersonating) {
      toast({
        description: 'Changing gallery locations on behalf of your clients is not supported at this time.',
        status: 'warning'
      })
    } else {
      setShowGooglePlaceSelector(true)
    }
  }

  useEffectOncePerSessionConditionally(
    () => {
      onShowGooglePlaceSelector()
      history.push({ search: '' })
    },
    undefined,
    hasChangeLocationQs
  )

  const onLocationChange = async (place: CurrentSelectedLocation) => {
    if (!isAuthTokenSupported()) {
      return
    }
    setCurrentLocation(place)
    setShowGooglePlaceSelector(false)
    if (!data?.feeds) return
    try {
      getPlacePredictions({
        input: place.description,
        componentRestrictions: {
          country: 'us'
        }
      })
      await changeFeedsLocation({
        feedIds: data?.feeds?.map(feed => feed?.id as string),
        googlePlaceId: place.place_id
      })
      refreshFeeds()
    } catch {
      dispatch(
        addToast({
          message: intl.formatMessage(MSG.failedChangeLocation),
          status: 'error'
        })
      )
    }
  }

  const tilesCategories = useMemo(() => {
    return {
      category: {
        title: intl.formatMessage(MSG.yourStuff),
        tiles: [
          {
            icon: <Image h={4} w={4} loading='lazy' src={savedSearch} alt='savedSearch' />,
            label: intl.formatMessage(MSG.savedSearch),
            onClick: () => {
              trackingClient?.linkInteraction({
                ui_context: 'Gallery_SavedSearch_Card',
                descriptor: 'Clicked on Saved Search',
                action: 'Clicked',
                guid: '3GtpqMPqaHLD7YUKKiAyjg'
              })
              history.push(`/home-search/${customerProfileId}/saved-searches`)
            }
          },
          {
            icon: <Image h={4} w={4} loading='lazy' src={favorites} alt='favorites' />,
            label: intl.formatMessage(MSG.favorites),
            onClick: () => {
              trackingClient?.linkInteraction({
                ui_context: 'Gallery_Favorites_Card',
                descriptor: 'Clicked on Favorites',
                action: 'Clicked',
                guid: '3GtcZMPqaHLD7YUKKiAyjg'
              })
              history.push(SEARCH_PATH_HELPERS.favoriteListings.buildPath(customerProfileId, clientId))
            }
          }
        ] as Tile[]
      },
      local: {
        title: intl.formatMessage(MSG.localGalleries),
        tiles: galleryTiles
      }
    }
  }, [data?.feeds])

  const showPageLevelLoader = useMemo(() => {
    return loadingMarketInterests || !currentLocation
  }, [loadingMarketInterests, currentLocation])

  const removeDuplicateRecentlySoldFeeds = (feeds: FeedBase[]) => {
    const firstRecentlySoldIndex = feeds.findIndex(feed => feed.feedType === 'recentlySold')
    if (firstRecentlySoldIndex === -1) return
    const secondRecentlySoldIndex = feeds.findIndex(
      (feed, i) =>
        feed.feedType === 'recentlySold' &&
        i !== firstRecentlySoldIndex &&
        feed.location?.zipCode === feeds[firstRecentlySoldIndex].location?.zipCode
    )
    if (secondRecentlySoldIndex !== -1) {
      feeds.splice(secondRecentlySoldIndex, 1)
    }
  }

  const applyGallerySortOrder = (feeds: FeedBase[], experiment: ExperimentClient | undefined) => {
    if (!experiment || !feeds?.length) return

    experiment.exposure('gallery-sort-order')

    const variant = experiment.variant('gallery-sort-order')
    if (!variant || variant.value === 'control') return

    const feedOrder = EXPERIMENT_SORT_ORDERS[variant.value as keyof typeof EXPERIMENT_SORT_ORDERS]
    if (feedOrder) {
      feeds.sort((a, b) => feedOrder.indexOf(a.feedType) - feedOrder.indexOf(b.feedType))
    }
  }

  useEffect(() => {
    if (data?.feeds) {
      removeDuplicateRecentlySoldFeeds(data.feeds)
      applyGallerySortOrder(data.feeds, experiment)
    }
  }, [data?.feeds])

  return (
    <AppLayout loading={showPageLevelLoader} enableAgentDropdown match={match}>
      {showNotAuthorizedModal && <NoPreviewModeModal />}
      {showGooglePlaceSelector && (
        <GoogleAutoComplete onClose={() => setShowGooglePlaceSelector(false)} onPlaceSelected={onLocationChange} />
      )}
      <Box
        mt={-2}
        pt={8}
        pb={7}
        px={[5, null, 20]}
        backgroundImage={`url(${theme.isLightThemeType ? topographicalMapLight : topographicalMapDark})`}
        backgroundSize='cover'
        backgroundBlendMode='multiply'
        backgroundColor='whiteAlpha.50'
        _dark={{
          backgroundColor: 'blackAlpha.600'
        }}
      >
        <Flex pb={4} alignItems='center' justifyContent='space-between'>
          <Box>
            <Heading as='h1' size='xl' textAlign='left' m={0} fontWeight={600}>
              {intl.formatMessage(MSG.forYou)}
            </Heading>
            <Text m={0} size='xs'>
              {intl.formatMessage(MSG.help)}
            </Text>
          </Box>
          <Box flex={[1, null, 0.3]}>
            <Text color='neutral.900' fontWeight='semibold' textAlign='right' size={['sm', null, 'md']} m={0}>
              <Icon mr={2} name='map-marker-mdc' />
              {currentLocation?.name || currentLocation?.description}
            </Text>
            <Text
              cursor='pointer'
              onClick={onShowGooglePlaceSelector}
              textColor='primary.500'
              float='right'
              size='xs'
              m={0}
            >
              {intl.formatMessage(MSG.changeLocation)}
            </Text>
          </Box>
        </Flex>
        <Stack direction='row' spacing={2} overflowX='auto' flexWrap='nowrap'>
          {Object.keys(tilesCategories).map(key => {
            const category = tilesCategories[key] as { title: string; tiles: Tile[] }
            if (!category.tiles?.length) return null
            return (
              <Flex key={key} flex='0 0 auto' direction='column'>
                <Box>
                  <Text fontWeight={600} mb={1} size='sm'>
                    {category.title}
                  </Text>
                </Box>
                <Stack direction='row' spacing={2}>
                  {category.tiles.map((tile, index) => (
                    <ListingGalleryTile key={index} icon={tile.icon} title={tile.label} onClick={tile.onClick} />
                  ))}
                </Stack>
              </Flex>
            )
          })}
        </Stack>
      </Box>
      <Box
        // @ts-ignore
        background='linear-gradient(0.25turn, var(--hb-colors-primary-500), var(--hb-colors-text-brand))'
        h='0.0625rem'
        w='100%'
      />
      <Box px={[5, null, 20]} mt={5}>
        {data?.feeds?.map(feed => {
          if (!MSG[feed?.feedType]) {
            return null
          }

          return <Feed key={feed?.id} feed={feed as FeedBase} />
        })}
      </Box>
      {hasNativeAccess && (
        <Box margin={8}>
          {isMediumOrAbove ? (
            <DownloadMobileAppPromptCard isLandingPage={true} />
          ) : (
            <MobileDownloadMobileAppPromptCard isLandingPage={true} />
          )}
        </Box>
      )}
      <HomeSearchFooter />
    </AppLayout>
  )
}
