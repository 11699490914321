import get from 'lodash/get'
import { defineMessages, useIntl } from 'react-intl'
import { ExternalLinkButton, Text } from '../..'
import { safeExternal } from '../../../helpers/links'
import {
  selectCustomerFirstName,
  selectCustomerProfile,
  selectLoanApplicationCtaEnabled,
  selectLoanApplicationUri
} from '../../../store/selectors/customerProfile'
import { selectRouteKey } from '../../../store/selectors/routes'
import CustomerTitle from '../CustomerTitle'
import Email from '../Email'
import NMLS from '../NMLS'
import Phone from '../Phone'
import { Player } from '../Player'
import { SponsorBranding } from '../SponsorBranding'
import QuestionSingle from './QuestionSingleContainer'
import mockAgent from './mockAgent'
import { useSelector } from 'react-redux'
import { selectShouldRenderSponsorBranding } from '../../../store/selectors/brandingStats'
import {
  selectSponsorship,
  selectSponsorshipFirstName,
  selectSponsorshipFullName
} from '../../../store/selectors/sponsorship'
import { selectCoBrandedIntroVideoId, selectCoBrandedIntroVideoType } from '../../../store/selectors/videos'
import { RootState } from '../../../types/rootState'
import { Box, Avatar } from '@homebotapp/hb-react-component-catalog'
import styles from './Questions.module.scss'
import { LeavingHomebotCtaButton } from '../LeavingHomebotCta/LeavingHomebotCtaButton'
import { PREQUAL_TOPIC_KEYS, TopicKey } from '../../../constants/customerMessages'
import { PrequalSwitch } from '../PrequalSwitch/PrequalSwitch'
import { useState } from 'react'

export const MSG = defineMessages({
  head: {
    id: 'Questions.headline',
    defaultMessage: 'Want to learn more?'
  },
  phone: {
    id: 'Questions.phone',
    defaultMessage: 'Phone'
  },
  website: {
    id: 'Questions.website',
    defaultMessage: 'Website'
  },
  cospo: {
    id: 'Questions.cospoMessage',
    defaultMessage:
      "{customerName} speaks highly of {cospoName}, a lender that is happy to help answer questions for {customerName}'s clients and show how scenarios can save money."
  },
  ask: {
    id: 'RefiDetailsModal.questions.ask',
    defaultMessage: 'Any questions for {name}?'
  },
  expert: {
    id: 'Questions.expert',
    defaultMessage: 'Ask your expert'
  },
  from: {
    id: 'Questions.from',
    defaultMessage: 'from {corpName}'
  },
  help: {
    id: 'Questions.help',
    defaultMessage: "to help answer what's on your mind."
  },
  cta: {
    id: 'Questions.ctaButtonText',
    defaultMessage: 'Review your financing options'
  }
})

interface QuestionsProps {
  disableCospo?: boolean
  questionTopic: string | string[]
  questionTopicKey: string | string[]
  questions: { question?: string; placeholder?: string }[]
  renderHeader?: () => JSX.Element
  children?: React.ReactNode
}

const Questions = ({
  disableCospo,
  questionTopic,
  questionTopicKey,
  questions,
  renderHeader,
  children
}: QuestionsProps) => {
  const intl = useIntl()
  const isBuyer = useSelector(selectRouteKey) === 'buy'
  const customerProfile = useSelector(selectCustomerProfile) || {}
  const testDrive = useSelector<RootState, boolean>(state => state.buyerInfo.testDrive)
  const tempSponsor = useSelector(selectSponsorship)
  const sponsor = disableCospo ? {} : tempSponsor
  const customerFirstName = useSelector(selectCustomerFirstName)
  const sponsorshipFirstName = useSelector(selectSponsorshipFirstName)
  const sponsorshipFullName = useSelector(selectSponsorshipFullName)
  const sponsorVideoId = useSelector(selectCoBrandedIntroVideoId)
  const sponsorVideoType = useSelector(selectCoBrandedIntroVideoType)
  const renderSponsorBranding = useSelector(selectShouldRenderSponsorBranding)
  const loanApplicationUri = useSelector(selectLoanApplicationUri)
  const loanApplicatioCtaEnabled = useSelector(selectLoanApplicationCtaEnabled)
  const showCta = loanApplicatioCtaEnabled && loanApplicationUri
  const [hasSentDM, setHasSentDM] = useState(false)

  const isCoSpo = () => {
    const agent = getRenderedAgent()
    return agent !== customerProfile
  }

  const getRenderedAgent = () => {
    if (sponsor && sponsor.id) {
      return sponsor
    }
    if (isBuyer && testDrive && customerProfile.type === 'real-estate-agents') {
      return mockAgent
    }

    return customerProfile
  }

  const renderCoSpoMessage = () => {
    return (
      <div className={styles.cospo}>
        {renderSponsorBranding && <SponsorBranding className={styles.sponsorBranding} />}
        {!renderSponsorBranding && (
          <Text variant='neutralCool' variantCount='700' size='s' className={styles.cospoMessage}>
            {intl.formatMessage(MSG.cospo, {
              customerName: customerFirstName,
              cospoName: (
                <Text element='span' variant='neutralCool' variantCount='900'>
                  {sponsorshipFullName}
                </Text>
              )
            })}
          </Text>
        )}
        {sponsorVideoId && sponsorVideoType && (
          <Player className={styles.sponsorVideo} videoId={sponsorVideoId} videoType={sponsorVideoType} />
        )}
        <h3>{intl.formatMessage(MSG.ask, { name: sponsorshipFirstName })}</h3>
      </div>
    )
  }

  const renderAgent = () => {
    const agent = getRenderedAgent()
    const phone = get(agent, 'mobilePhone', agent.workPhone)

    return (
      <div className={styles.agentContainer} data-qa='contactInfo'>
        <Avatar mb={-8} transform='translateY(-50%)' src={agent.photoUri || agent.photo} />
        <div className={styles.agentContact}>
          <NMLS size='smallest' variant='neutralCool' variantCount='700' value={agent.nmls} />
          <CustomerTitle size='tiny' value={agent.title} />
          <Text weight='bold' variant='neutralCool' variantCount='900'>
            {agent.firstName} {agent.lastName}
          </Text>
          {phone && <Phone value={phone} className={styles.phone} />}
          <Email className={styles.email} value={agent.email} />
          <div className={styles.agentSocial}>
            {agent.websiteUri && (
              <div>
                <Text size='s' weight='semibold' variant='neutralCool' variantCount='800'>
                  {intl.formatMessage(MSG.website)}
                </Text>
                <ExternalLinkButton className={styles.agentWebsite} minimal href={safeExternal(agent.websiteUri)}>
                  {agent.websiteUri}
                </ExternalLinkButton>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderSponsorContent = isCoSpo() && !disableCospo
  const agent = getRenderedAgent()
  const showPrequalCheckboxAtTheTopOfQuestionsStack = Array.isArray(questionTopicKey)
    ? questionTopicKey?.some(key => PREQUAL_TOPIC_KEYS.includes(key as TopicKey))
    : PREQUAL_TOPIC_KEYS.includes(questionTopicKey as TopicKey)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {typeof renderHeader === 'function' ? (
          <div className={styles.headline}>{renderHeader()}</div>
        ) : (
          <h2 className={styles.headline}>{intl.formatMessage(MSG.head)}</h2>
        )}
        <Text element='span' align='center' variant='neutralCool' variantCount='700'>
          {intl.formatMessage(MSG.expert)}{' '}
        </Text>
        {agent.corporationName && (
          <Text element='span' variant='neutralCool' variantCount='700'>
            {intl.formatMessage(MSG.from, { corpName: <strong>{agent.corporationName}</strong> })}{' '}
          </Text>
        )}
        <Text element='span' align='center' variant='neutralCool' variantCount='700'>
          {intl.formatMessage(MSG.help)}
        </Text>
        {showCta && (
          <Box mt={6} mb={8}>
            <LeavingHomebotCtaButton
              fullWidth
              ctaButtonSize='md'
              ctaButtonVariant='ghost'
              ctaButtonColorScheme='primary'
              ctaLinkUrl={loanApplicationUri}
              tracking={{
                guid: '8qFN5wrhydLsk5vZo3A9Tm',
                ui_context: 'Questions Section',
                descriptor: 'Cta button clicked'
              }}
            >
              {intl.formatMessage(MSG.cta)}
            </LeavingHomebotCtaButton>
          </Box>
        )}
      </div>
      {renderSponsorContent && renderCoSpoMessage()}
      {children}
      <div data-qa='dm-message-container' className={styles.messageContainer}>
        <div>
          <div className={styles.questions}>
            {showPrequalCheckboxAtTheTopOfQuestionsStack && (
              <PrequalSwitch hasSentDM={hasSentDM} setHasSentDM={setHasSentDM} />
            )}
            {questions.map(({ question, placeholder }, i) => (
              <QuestionSingle
                key={i}
                name={`input_${i}`}
                question={question}
                placeholder={placeholder}
                showPrequalSwitchUnderSingleQuestion={!showPrequalCheckboxAtTheTopOfQuestionsStack}
                setHasSentDM={setHasSentDM}
                questionTopic={
                  Array.isArray(questionTopic) && questionTopic.length > i
                    ? questionTopic[i]
                    : (questionTopic as string)
                }
                questionTopicKey={
                  Array.isArray(questionTopicKey) && questionTopicKey.length > i
                    ? questionTopicKey[i]
                    : (questionTopicKey as string)
                }
              />
            ))}
          </div>
        </div>
        <div className={styles.contactInfo}>{renderAgent()}</div>
      </div>
    </div>
  )
}

export default Questions
