/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: { input: number; output: number }
}

/** TransUnion Credit report Account Type */
export enum AccountType {
  AutoLoan = 'AutoLoan',
  Collections = 'Collections',
  CreditCard = 'CreditCard',
  Mortgage = 'Mortgage',
  Other = 'Other',
  StudentLoan = 'StudentLoan',
  UnsecuredPersonalLoan = 'UnsecuredPersonalLoan'
}

/** AI Chat Response */
export type AiChat = {
  __typename?: 'AiChat'
  messages: Array<Maybe<AiMessage>>
  /** AI response */
  text: Scalars['String']['output']
}

/** AI Chat History */
export type AiChatHistory = {
  __typename?: 'AiChatHistory'
  messages: Array<Maybe<AiMessage>>
}

/** AI Chat Input */
export type AiChatInput = {
  /** Arbitrary data, to pass to the resolver, for dev purposes */
  data?: InputMaybe<Scalars['JSONObject']['input']>
  /** User message */
  message: Scalars['String']['input']
}

/** Element of chat history */
export type AiMessage = {
  __typename?: 'AiMessage'
  content: Array<AiMessageContent>
  /**
   * In fact it's enum: `model` and `user`.
   * Messages of `system` and `tool` are not exposed"
   */
  role: Scalars['String']['output']
}

/** Element of content array */
export type AiMessageContent = {
  __typename?: 'AiMessageContent'
  text: Scalars['String']['output']
}

/** BoundingBox */
export type BoundingBox = {
  __typename?: 'BoundingBox'
  bottomRight: CoordinatesOutput
  level: BoundingBoxLevel
  topLeft: CoordinatesOutput
}

/** BoundingBox Input */
export type BoundingBoxInput = {
  bottomRight: Coordinates
  level: BoundingBoxLevel
  topLeft: Coordinates
}

/** BoundingBox Search Level */
export enum BoundingBoxLevel {
  BoundingBox = 'bounding_box'
}

/** Listing brokerage object */
export type Brokerage = {
  __typename?: 'Brokerage'
  /** Brokerage name */
  name?: Maybe<Scalars['String']['output']>
  /** Brokerage phone */
  phone?: Maybe<Scalars['String']['output']>
}

/** Bucketed Monthly Home Expenses */
export type BucketedMonthlyHomeExpenses = {
  __typename?: 'BucketedMonthlyHomeExpenses'
  homeInsurance: Scalars['Float']['output']
  monthlyHoaFees: Scalars['Float']['output']
  monthlyPrincipalAndInterest: Scalars['Float']['output']
  mortgageInsurance: Scalars['Float']['output']
  propertyTaxes: Scalars['Float']['output']
  secondMortgagePrincipalAndInterest?: Maybe<Scalars['Float']['output']>
  /** TODO: shall we return it as Int! ? */
  totalMonthlyHomeExpenses: Scalars['Float']['output']
}

/**
 * Subset of MessageTopicKey enum
 * Doesn't enforced by gql, but resolver will validate that only valid values are passed
 * NOTE: We need to manually keep this in sync with MessageTopicKey
 */
export enum BuyerMessageTopicKey {
  BuyerAssumableInquiryToLoCustomer = 'buyer_assumable_inquiry_to_lo_customer',
  BuyerAssumableInquiryToSponsorWithReaNotified = 'buyer_assumable_inquiry_to_sponsor_with_rea_notified',
  BuyerAssumableInquiryToUnsponsoredRea = 'buyer_assumable_inquiry_to_unsponsored_rea',
  BuyerGeneralInquiryToCustomer = 'buyer_general_inquiry_to_customer'
}

/** Buying Power response */
export type BuyingPower = {
  __typename?: 'BuyingPower'
  BEDTI: Scalars['Float']['output']
  FEDTI: Scalars['Float']['output']
  LTV: Scalars['Float']['output']
  buyingPower: Scalars['Float']['output']
  closingCosts: Scalars['Float']['output']
  constrainedByDTI?: Maybe<Scalars['Boolean']['output']>
  constrainedByPreferredPayment: Scalars['Boolean']['output']
  downPayment: Scalars['Float']['output']
  dpaProgram?: Maybe<Scalars['Boolean']['output']>
  firstMortgageAmount?: Maybe<Scalars['Float']['output']>
  flexFunds: Scalars['Float']['output']
  interestRate: Scalars['Float']['output']
  loanAmount: Scalars['Float']['output']
  programName: Scalars['String']['output']
  qualifies: Scalars['Boolean']['output']
  rejectionReason: Scalars['String']['output']
  reserves: Scalars['Float']['output']
  secondMortgageAmount?: Maybe<Scalars['Float']['output']>
  totalMonthlyHomeExpenses: Scalars['Float']['output']
}

/** Update Client Response - not completely mapped out yet */
export type Client = {
  __typename?: 'Client'
  affordability?: Maybe<Scalars['Int']['output']>
  agentNote?: Maybe<Scalars['String']['output']>
  borrowerType?: Maybe<Scalars['String']['output']>
  buyerDigestUnsubscribedAt?: Maybe<Scalars['String']['output']>
  buyersAccess?: Maybe<Scalars['Boolean']['output']>
  clientHomesCount?: Maybe<Scalars['Int']['output']>
  closeDate?: Maybe<Scalars['String']['output']>
  closeReason?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  customerProfileId?: Maybe<Scalars['String']['output']>
  debt?: Maybe<Scalars['Int']['output']>
  digestEmailUnsubscribed?: Maybe<Scalars['Boolean']['output']>
  digestEnabled?: Maybe<Scalars['Boolean']['output']>
  digestHasBeenSent?: Maybe<Scalars['Boolean']['output']>
  digestScheduledAt?: Maybe<Scalars['String']['output']>
  downPayment?: Maybe<Scalars['Int']['output']>
  email?: Maybe<Scalars['String']['output']>
  emailValid?: Maybe<Scalars['Boolean']['output']>
  emailVerificationCode?: Maybe<Scalars['String']['output']>
  engagementFlag?: Maybe<Scalars['Int']['output']>
  favoriteListingsAlertCadenceDays?: Maybe<Scalars['Int']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  hasDownloadedNativeApp?: Maybe<Scalars['Boolean']['output']>
  hbbAccess?: Maybe<Scalars['Boolean']['output']>
  inactive?: Maybe<Scalars['Boolean']['output']>
  income?: Maybe<Scalars['Int']['output']>
  invited?: Maybe<Scalars['Boolean']['output']>
  invitedType?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  lastNativeAppOpenedAt?: Maybe<Scalars['String']['output']>
  leadSource?: Maybe<Scalars['String']['output']>
  legacyRef?: Maybe<Scalars['String']['output']>
  listingAlertsUnsubscribedAt?: Maybe<Scalars['String']['output']>
  loanTerm?: Maybe<Scalars['Int']['output']>
  locale?: Maybe<Scalars['String']['output']>
  lockboxUid?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  prefAgentEmail?: Maybe<Scalars['String']['output']>
  prefAgentFirstName?: Maybe<Scalars['String']['output']>
  prefAgentLastName?: Maybe<Scalars['String']['output']>
  smsOptedInAt?: Maybe<Scalars['String']['output']>
  smsUnsubscribedAt?: Maybe<Scalars['String']['output']>
  smsVerificationCode?: Maybe<Scalars['String']['output']>
  subscribedToBuyerDigest?: Maybe<Scalars['Boolean']['output']>
  subscribedToHomeDigest?: Maybe<Scalars['Boolean']['output']>
  subscribedToHomeMarket?: Maybe<Scalars['Boolean']['output']>
  subscribedToListingAlerts?: Maybe<Scalars['Boolean']['output']>
  unsubscribedFromCustomer?: Maybe<Scalars['Boolean']['output']>
  unsubscribedFromCustomerAt?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  zipcodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** Client Event Input */
export type ClientEventInput = {
  eventName: ClientEventName
  /** Required for Viewed event, see *ClientEventName* comments */
  eventSource?: InputMaybe<ClientEventSource>
  /** Required for some events, see *ClientEventName* comments */
  feedType?: InputMaybe<FeedType>
  /** Required for some events, see *ClientEventName* comments */
  homeId?: InputMaybe<Scalars['String']['input']>
  /**
   * If event is related to a listing and *listingId* is provided then
   * *listing_url* and *address* will be added to the event *properties*
   */
  listingId?: InputMaybe<Scalars['String']['input']>
  /** Required for open house events */
  openHouseId?: InputMaybe<Scalars['String']['input']>
  /**
   * Kitchen sink, will be passed to the event.
   *
   * *mobile: boolean* property will be added to the object
   */
  properties?: InputMaybe<Scalars['JSONObject']['input']>
}

/**
 * The names are not the same as in mikasa.
 * They will be mapped to the correct values by gqlactus.
 * See *ClientEventName* in src/clientEvents/types.ts for the mapping
 */
export enum ClientEventName {
  /** Search event */
  BuyerViewedHomeSearchEvent = 'BuyerViewedHomeSearchEvent',
  /** Buyer Digest event */
  BuyerViewedMarkets = 'BuyerViewedMarkets',
  /** Buyer Digest event */
  BuyerViewedReport = 'BuyerViewedReport',
  /** Home event, *homeId* is required */
  CmaRequest = 'CmaRequest',
  /** Search event */
  DetailsPageViewedEvent = 'DetailsPageViewedEvent',
  /** Home event, *homeId* is required */
  ExpandedCashPosition = 'ExpandedCashPosition',
  /** Home event, *homeId* is required */
  ExpandedHomeAppreciation = 'ExpandedHomeAppreciation',
  /** Home event, *homeId* is required */
  ExpandedMarketTemp = 'ExpandedMarketTemp',
  /** Search event, *feedType* is required */
  GalleryViewed = 'GalleryViewed',
  /** Home event, *homeId* is required */
  InstantOfferRequested = 'InstantOfferRequested',
  /** Search event */
  ListingFavoritedEvent = 'ListingFavoritedEvent',
  /**
   * Search Event
   * EventSource is required for this event
   */
  ListingPhotoInteraction = 'ListingPhotoInteraction',
  ListingUnfavoritedEvent = 'ListingUnfavoritedEvent',
  /**
   * If *homeId* is provided, the event will be sent as home event
   * Otherwise, the event will be sent as buyer event
   */
  LoanApplicationCtaClick = 'LoanApplicationCtaClick',
  /**
   * New Generic viewed event
   * If *homeId* is provided, the event will be sent as home event.
   * Otherwise, the event will be sent as search event
   * EventSource is required for these events
   */
  ModuleViewed = 'ModuleViewed',
  /** Home event, *homeId* is required */
  MovedCashPositionSlider = 'MovedCashPositionSlider',
  /** Search event */
  OpenHouseAddedToCalendar = 'OpenHouseAddedToCalendar',
  /** Home event, *homeId* is required */
  ReferralInviteSent = 'ReferralInviteSent',
  /** Home event, *homeId* is required */
  RefiSliderInteraction = 'RefiSliderInteraction',
  /** Search event */
  SavedSearchCreatedEvent = 'SavedSearchCreatedEvent',
  /**
   * If *homeId* is provided, the event will be sent as home event.
   * Otherwise, the event will be sent as search event
   * EventSource is required for this events
   */
  ScheduleACallCtaClick = 'ScheduleACallCtaClick',
  /**
   * If *homeId* is provided, the event will be sent as home event.
   * Otherwise, the event will be sent as search event
   * EventSource is required for these events
   */
  View = 'View',
  /** Home event, *homeId* is required */
  ViewedDreamHomePanel = 'ViewedDreamHomePanel',
  /** Home event, *homeId* is required */
  ViewedHomeInsurancePanel = 'ViewedHomeInsurancePanel',
  /** Home event, *homeId* is required */
  ViewedMarketPanel = 'ViewedMarketPanel',
  /** Home event, *homeId* is required */
  ViewedRefiDetails = 'ViewedRefiDetails',
  /** Home event, *homeId* is required */
  ViewedReverseMortgagePanel = 'ViewedReverseMortgagePanel',
  /** Home event, *homeId* is required */
  ViewedShouldYouSell = 'ViewedShouldYouSell',
  /**
   * If *homeId* is provided, the event will be sent as home event.
   * Otherwise, the event will be sent as search event
   */
  ViewedVideoEmail = 'ViewedVideoEmail'
}

/**
 * The names are not the same as in mikasa.
 * They will be mapped to the correct values by gqlactus.
 * See *ClientEventSource* in src/clientEvents/types.ts for the mapping
 */
export enum ClientEventSource {
  /** Buyer Digest */
  BuyerDigest = 'BuyerDigest',
  /** Home Digest */
  Digest = 'Digest',
  /** Favorite Properties page */
  FavoriteProperties = 'FavoriteProperties',
  /** For You page */
  ForYou = 'ForYou',
  /** Gallery individual page */
  Gallery = 'Gallery',
  /** Listing Detailed page */
  ListingDetailed = 'ListingDetailed',
  /** Listings Search main page */
  ListingsSearch = 'ListingsSearch',
  /** Saved Searches page */
  SavedSearches = 'SavedSearches',
  /** Home Search - Deprecated */
  Search = 'Search'
}

/** Updated Client's favorite listings */
export type ClientsFavoriteListings = {
  __typename?: 'ClientsFavoriteListings'
  /** Array of favorite listings */
  favoriteListings: Array<Maybe<FavoriteListing>>
}

/** Coordinates object */
export type Coordinates = {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

/** Coordinates object */
export type CoordinatesOutput = {
  __typename?: 'CoordinatesOutput'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

/** Create Feed response */
export type CreateFeedResponse = {
  __typename?: 'CreateFeedResponse'
  feedId: Scalars['ID']['output']
}

/** Returning id only, FE will refetch saved searches in any case */
export type CreateSavedSearchResult = {
  __typename?: 'CreateSavedSearchResult'
  id: Scalars['ID']['output']
}

/** Ephemeral Recently Sold Feed object */
export type EphemeralFeed = {
  __typename?: 'EphemeralFeed'
  /** Might be useful for caching */
  id: Scalars['ID']['output']
  /** The array up to 3 listings, might be empty */
  listings: Array<Maybe<ListingCompact>>
  /** Original zip code, sending it back for the client */
  zipCode: Scalars['String']['output']
}

/** Home Feed Input */
export type EphemeralFeedsInput = {
  zipCode: Scalars['String']['input']
}

/** Smart Search response */
export type EquitySmartSearch = {
  __typename?: 'EquitySmartSearch'
  brackets: Array<Maybe<SmartSearchBracket>>
  homeValue: Scalars['Float']['output']
}

/** Favorite Listing that was updated */
export type FavoriteListing = {
  __typename?: 'FavoriteListing'
  addressKey?: Maybe<Scalars['String']['output']>
  /**
   * In days, possible values: 0, 1, 7
   * Will be null if Alert is not set/never
   */
  alertCadenceDays?: Maybe<Scalars['Int']['output']>
  clientId?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  createdAt?: Maybe<Scalars['String']['output']>
  /** ListingId */
  id: Scalars['ID']['output']
  /** ISO date string */
  lastNotificationAtWeb?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastSeenAt?: Maybe<Scalars['String']['output']>
  priceSnapshot?: Maybe<Scalars['Int']['output']>
  /** ISO date string */
  snapshottedAt?: Maybe<Scalars['String']['output']>
  /** Listing status */
  statusSnapshot?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

/** Favorite Listings of the user */
export type FavoriteListings = {
  __typename?: 'FavoriteListings'
  /** Array of favorite listings, might be empty */
  listings: Array<Maybe<Listing>>
}

/** Feed object, contains compact listings and metadata */
export type Feed = {
  __typename?: 'Feed'
  feedType: FeedType
  homeId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** ISO date string */
  lastNotificationAtNative?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastNotificationAtWeb?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastSeenAt?: Maybe<Scalars['String']['output']>
  listings: Array<Maybe<ListingCompact>>
  location: GeoArea
  subscribed: Scalars['Boolean']['output']
  /** ISO date string */
  subscribedAt?: Maybe<Scalars['String']['output']>
  webUserId: Scalars['String']['output']
}

/** Feed Base object, contains metadata */
export type FeedBase = {
  __typename?: 'FeedBase'
  feedType: FeedType
  /** optional; associates the feed with a user's home location */
  homeId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** ISO date string */
  lastNotificationAtNative?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastNotificationAtWeb?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastSeenAt?: Maybe<Scalars['String']['output']>
  location: GeoArea
  subscribed: Scalars['Boolean']['output']
  /** ISO date string */
  subscribedAt?: Maybe<Scalars['String']['output']>
  webUserId: Scalars['String']['output']
}

/** Create Feed Input */
export type FeedInput = {
  clientId?: InputMaybe<Scalars['String']['input']>
  feedType: FeedType
  googlePlaceId: Scalars['String']['input']
}

/** Feed options */
export type FeedOptions = {
  /**
   * Used by recentlySold and recentPriceDrop feeds to sort listings.
   * If not provided, backend will try use desired home price
   * or fetch users homes and use the max price."
   */
  priceAnchor?: InputMaybe<Scalars['Int']['input']>
}

/** Feed Type enum */
export enum FeedType {
  /** Assumables - Assumable Mortgage */
  Assumables = 'assumables',
  /** Buyer Perks - Seller Concessions */
  Concessions = 'concessions',
  /** Open Houses - Listings with Open Houses */
  OpenHouses = 'openHouses',
  /** Recent Price Drop */
  RecentPriceDrop = 'recentPriceDrop',
  /** Recently Sold */
  RecentlySold = 'recentlySold'
}

/** This object is used to represent a location in a Feed and in the Search for GeoArea */
export type GeoArea = {
  __typename?: 'GeoArea'
  /** City name, guaranteed for level === city */
  city?: Maybe<Scalars['String']['output']>
  latitude: Scalars['Float']['output']
  level: GeoAreaLevel
  longitude: Scalars['Float']['output']
  name: Scalars['String']['output']
  placeId: Scalars['String']['output']
  stateAbbreviation: Scalars['String']['output']
  /** guaranteed for level === postal_code */
  zipCode?: Maybe<Scalars['String']['output']>
}

/** The same as GeoArea, but input type for mutation */
export type GeoAreaInput = {
  /** City name, guaranteed for level === city */
  city?: InputMaybe<Scalars['String']['input']>
  /** Required for level === city */
  latitude?: InputMaybe<Scalars['Float']['input']>
  level: GeoAreaLevel
  /** Required for level === city */
  longitude?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  /**
   * Google place ID, not in use as of 2024-04-23
   * Likely will be used in the future
   */
  placeId?: InputMaybe<Scalars['String']['input']>
  stateAbbreviation: Scalars['String']['input']
  /** guaranteed for level === postal_code */
  zipCode?: InputMaybe<Scalars['String']['input']>
}

/** GeoAreaLevel of Search Level */
export enum GeoAreaLevel {
  City = 'city',
  PostalCode = 'postal_code',
  State = 'state'
}

/** Update Home Response - not completely mapped out yet */
export type Home = {
  __typename?: 'Home'
  addressStreet?: Maybe<Scalars['String']['output']>
  addressUnit?: Maybe<Scalars['String']['output']>
  addressZip?: Maybe<Scalars['String']['output']>
  appraisedDate?: Maybe<Scalars['String']['output']>
  appraisedValue?: Maybe<Scalars['Int']['output']>
  bathrooms?: Maybe<Scalars['Int']['output']>
  bedrooms?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  customerProfileId?: Maybe<Scalars['String']['output']>
  expensesTotal?: Maybe<Scalars['Int']['output']>
  externalMappings?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  finishedSqft?: Maybe<Scalars['Int']['output']>
  floodInsuranceMonthly?: Maybe<Scalars['Int']['output']>
  halfBaths?: Maybe<Scalars['Int']['output']>
  hasRequestedCma?: Maybe<Scalars['String']['output']>
  hazardInsuranceCompany?: Maybe<Scalars['String']['output']>
  hazardInsuranceMonthly?: Maybe<Scalars['Int']['output']>
  hoaDues?: Maybe<Scalars['Int']['output']>
  insurancePolicyStartDate?: Maybe<Scalars['String']['output']>
  legacyRef?: Maybe<Scalars['String']['output']>
  lotSizeSqft?: Maybe<Scalars['String']['output']>
  occupancyStatus?: Maybe<Scalars['String']['output']>
  quarterBaths?: Maybe<Scalars['Int']['output']>
  soldDate?: Maybe<Scalars['String']['output']>
  soldPrice?: Maybe<Scalars['Int']['output']>
  threeQuartersBaths?: Maybe<Scalars['Int']['output']>
  titleInsuranceCompany?: Maybe<Scalars['String']['output']>
  totalRooms?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  yearBuilt?: Maybe<Scalars['Int']['output']>
}

/**
 * Subset of MessageTopicKey enum
 * Doesn't enforced by gql, but resolver will validate that only valid values are passed
 * NOTE: We need to manually keep this in sync with MessageTopicKey
 */
export enum HomeMessageTopicKey {
  /** Home Related Messages */
  BuyerAffordabilityToCustomer = 'buyer_affordability_to_customer',
  BuyerGeneralInquiryToCustomer = 'buyer_general_inquiry_to_customer',
  BuyingDreamHomeToCustomerWithNotify = 'buying_dream_home_to_customer_with_notify',
  EquitySmartSearchToSponsor = 'equity_smart_search_to_sponsor',
  HomeAppreciationToCustomer = 'home_appreciation_to_customer',
  HomeImprovementsToSponsor = 'home_improvements_to_sponsor',
  HomeInsuranceToCustomer = 'home_insurance_to_customer',
  HomeValueToSponsor = 'home_value_to_sponsor',
  HomeownerAddSpaceToSponsor = 'homeowner_add_space_to_sponsor',
  HomeownerCashPositionToCustomerWithNotify = 'homeowner_cash_position_to_customer_with_notify',
  HomeownerCashoutOptionsToSponsor = 'homeowner_cashout_options_to_sponsor',
  HomeownerCreditCardDebtToSponsor = 'homeowner_credit_card_debt_to_sponsor',
  HomeownerEmailUpdateToCustomer = 'homeowner_email_update_to_customer',
  HomeownerEmergencyFundToSponsor = 'homeowner_emergency_fund_to_sponsor',
  HomeownerEnergyImprovementsToSponsor = 'homeowner_energy_improvements_to_sponsor',
  HomeownerInvestmentsToSponsor = 'homeowner_investments_to_sponsor',
  HomeownerMarketToCustomer = 'homeowner_market_to_customer',
  HomeownerShortTermRentalsToCustomer = 'homeowner_short_term_rentals_to_customer',
  HomeownerShouldSellMarketToCustomer = 'homeowner_should_sell_market_to_customer',
  HomeownerShouldSellToCustomer = 'homeowner_should_sell_to_customer',
  HomeownerStudentDebtToSponsor = 'homeowner_student_debt_to_sponsor',
  InvestmentPropertyToCustomerWithNotify = 'investment_property_to_customer_with_notify',
  MortgageCoachToCustomer = 'mortgage_coach_to_customer',
  MortgageInsuranceToSponsor = 'mortgage_insurance_to_sponsor',
  PrincipalPaymentsToSponsor = 'principal_payments_to_sponsor',
  PurchasingPowerToCustomer = 'purchasing_power_to_customer',
  RefinancingToSponsor = 'refinancing_to_sponsor',
  ReverseMortgageToSponsor = 'reverse_mortgage_to_sponsor',
  SecondHomeToCustomerWithNotify = 'second_home_to_customer_with_notify',
  SellingHomeToCustomerWithNotify = 'selling_home_to_customer_with_notify',
  UsingEquityToSponsor = 'using_equity_to_sponsor',
  VideoEmailToSponsor = 'video_email_to_sponsor',
  WhatToSellForToCustomer = 'what_to_sell_for_to_customer'
}

/** Listing object */
export type Listing = {
  __typename?: 'Listing'
  /** Address */
  address: ListingAddress
  /** Agent */
  agent?: Maybe<ListingAgent>
  /** alertCadenceDays */
  alertCadenceDays?: Maybe<Scalars['Int']['output']>
  /** Annual Property Taxes */
  annualPropertyTaxes?: Maybe<Scalars['Float']['output']>
  /** Remaining balance of the assumable loan */
  assumableLoanBalance?: Maybe<Scalars['Float']['output']>
  /** Loan type of the assumable loan (VA, FHA, etc) */
  assumableLoanType?: Maybe<Scalars['String']['output']>
  /** Estimated monthly payment of current assumable loan */
  assumableMonthlyPayment?: Maybe<Scalars['Float']['output']>
  /** Number of months homeowner has been in home, or number of payments made on the assumable loan */
  assumableMonthsInHome?: Maybe<Scalars['Int']['output']>
  /** Total term length of assumable loan (usually 360) */
  assumableNumLoanPayments?: Maybe<Scalars['Int']['output']>
  /** Assumable rate, % */
  assumableRatePercent?: Maybe<Scalars['Float']['output']>
  /** Number of bathrooms */
  bathroomsCount?: Maybe<Scalars['Int']['output']>
  /** Number of bedrooms */
  bedroomsCount?: Maybe<Scalars['Int']['output']>
  /** Brokerage */
  brokerage?: Maybe<Brokerage>
  /**
   * Buyer Brokerage Name
   * @deprecated Use complianceBrokerageInfo
   */
  buyerBrokerageName?: Maybe<Scalars['String']['output']>
  /** Close date */
  closeDate?: Maybe<Scalars['String']['output']>
  /** Close price in cents */
  closePriceCents?: Maybe<Scalars['SafeInt']['output']>
  /**
   * Brokerage Info For Compliance
   * Returned for BUYER_BROKERAGE_ATTRIBUTION_MLS only
   * Looks like: "Listing Brokerage Name • Buyer Brokerage Name"
   */
  complianceBrokerageInfo?: Maybe<Scalars['String']['output']>
  daysOnMarket?: Maybe<Scalars['Int']['output']>
  /** Description of the listing */
  description?: Maybe<Scalars['String']['output']>
  /** Disclaimer */
  disclaimer: Scalars['String']['output']
  /** Geohash for detecting listings on the same address used for combined markers */
  geohash?: Maybe<Scalars['String']['output']>
  /** Has Basement */
  hasBasement?: Maybe<Scalars['Boolean']['output']>
  /** Has Fireplace */
  hasFireplace?: Maybe<Scalars['Boolean']['output']>
  /** Has Garage */
  hasGarage?: Maybe<Scalars['Boolean']['output']>
  /** Has Pool */
  hasPool?: Maybe<Scalars['Boolean']['output']>
  hasSellerConcession?: Maybe<Scalars['Boolean']['output']>
  /** Has Spa */
  hasSpa?: Maybe<Scalars['Boolean']['output']>
  /** Has View */
  hasView?: Maybe<Scalars['Boolean']['output']>
  /** ID of the listing */
  id: Scalars['ID']['output']
  /** Image cover URL */
  imageCoverUrl: Scalars['String']['output']
  /** @deprecated Use internetAddressDisplayYn */
  internetAddressDisplayYN?: Maybe<Scalars['Boolean']['output']>
  /** Whether to show address on the listing */
  internetAddressDisplayYn?: Maybe<Scalars['Boolean']['output']>
  /** Listing has assumable rate */
  isAssumable: Scalars['Boolean']['output']
  /** MLS Logo URL */
  mlsLogo?: Maybe<Scalars['String']['output']>
  /** MLS Number */
  mlsNumber: Scalars['String']['output']
  /** Monthly HOA Fees */
  monthlyHoaFees?: Maybe<Scalars['Float']['output']>
  /** Open Houses */
  openHouses?: Maybe<Array<OpenHouse>>
  /** Originating System Name */
  originatingSystemName?: Maybe<Scalars['String']['output']>
  /** Previous list price in cents, 0 if not available */
  previousListPriceCents: Scalars['SafeInt']['output']
  /** Price in cents */
  priceCents: Scalars['SafeInt']['output']
  /** ISO date string */
  priceChangeTimestamp?: Maybe<Scalars['String']['output']>
  /** Property subtype, it's enum, but gql doesn't support spaces in enum values */
  propertySubtype?: Maybe<Scalars['String']['output']>
  /**
   * Property type
   * Enum values: see ListingCompact.propertyType
   */
  propertyType: Scalars['String']['output']
  /** Show map link */
  showMapLink?: Maybe<Scalars['Boolean']['output']>
  /** Lot square feet, might be 0 */
  sqftLot: Scalars['SafeInt']['output']
  /** Total square feet, might be 0 */
  sqftTotal: Scalars['Int']['output']
  /** Status of the listing, it's enum, but gql doesn't support spaces in enum values :( */
  status: Scalars['String']['output']
  /** Thumbnail image file names,  */
  thumbnailImageFileNames: Array<Scalars['String']['output']>
  /** Updated At - ISO date string */
  updatedAt: Scalars['String']['output']
  /** Utilities */
  utilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Year built */
  yearBuilt?: Maybe<Scalars['Int']['output']>
}

/** Address object */
export type ListingAddress = {
  __typename?: 'ListingAddress'
  /** City */
  city?: Maybe<Scalars['String']['output']>
  /** Latitude */
  latitude: Scalars['Float']['output']
  /** Longitude */
  longitude: Scalars['Float']['output']
  /** Street number */
  number?: Maybe<Scalars['String']['output']>
  /** Postal code */
  postalCode: Scalars['String']['output']
  /** State or province, two-letter abbreviation */
  stateOrProvince: Scalars['String']['output']
  /** Street name */
  street?: Maybe<Scalars['String']['output']>
  /** Unit number */
  unitNumber?: Maybe<Scalars['String']['output']>
}

/** Listing Agent object */
export type ListingAgent = {
  __typename?: 'ListingAgent'
  email?: Maybe<Scalars['String']['output']>
  mlsId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  stateLicenseNumber?: Maybe<Scalars['String']['output']>
  stateLicenseState?: Maybe<Scalars['String']['output']>
}

/** Compact Listing object */
export type ListingCompact = {
  __typename?: 'ListingCompact'
  address: ListingAddress
  /** Assumable Rate, %. Present only in Assumables Feeds */
  assumableRatePercent?: Maybe<Scalars['Float']['output']>
  bathroomsCount?: Maybe<Scalars['Int']['output']>
  bedroomsCount?: Maybe<Scalars['Int']['output']>
  brokerage?: Maybe<Brokerage>
  /** Available only in ListingsForArea when filtering by monthly expenses */
  bucketedMonthlyHomeExpenses?: Maybe<BucketedMonthlyHomeExpenses>
  /**
   * Buyer Brokerage Name
   * @deprecated Use complianceBrokerageInfo
   */
  buyerBrokerageName?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  closeDate?: Maybe<Scalars['String']['output']>
  closePriceCents?: Maybe<Scalars['SafeInt']['output']>
  /**
   * Brokerage Info For Compliance
   * Returned for BUYER_BROKERAGE_ATTRIBUTION_MLS only
   * Looks like: "Listing Brokerage Name • Buyer Brokerage Name"
   */
  complianceBrokerageInfo?: Maybe<Scalars['String']['output']>
  daysOnMarket?: Maybe<Scalars['Int']['output']>
  /** Geohash for detecting listings on the same address used for combined markers */
  geohash?: Maybe<Scalars['String']['output']>
  /** Does the listing have seller concession (buyer perks such as seller paying closing costs) */
  hasSellerConcession?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  /** @deprecated Use internetAddressDisplayYn */
  internetAddressDisplayYN?: Maybe<Scalars['Boolean']['output']>
  /** Whether to show address on the listing */
  internetAddressDisplayYn?: Maybe<Scalars['Boolean']['output']>
  /** Listing has assumable rate */
  isAssumable: Scalars['Boolean']['output']
  listing_url?: Maybe<Scalars['String']['output']>
  /** Available only in ListingsForArea when filtering by monthly expenses */
  loanInfo?: Maybe<ListingLoanInfo>
  mlsLogo?: Maybe<Scalars['String']['output']>
  mlsNumber: Scalars['String']['output']
  openHouses?: Maybe<Array<OpenHouse>>
  originatingSystemName: Scalars['String']['output']
  previousListPriceCents: Scalars['SafeInt']['output']
  priceCents: Scalars['SafeInt']['output']
  priceChangeTimestamp?: Maybe<Scalars['String']['output']>
  /**
   * Property type
   * Enum values as of 2042-05 are:
   *   Business Opportunity
   *   Commercial Lease
   *   Commercial Sale
   *   Commercial
   *   Condo
   *   Farm
   *   Land
   *   Lots and Land
   *   Mobile Home
   *   Rentals
   *   Residential Income
   *   Residential Lease
   *   Residential
   *   Timeshare
   * The list might evolve, resolver will validate that only valid values are passed
   */
  propertyType: Scalars['String']['output']
  rank?: Maybe<Scalars['Float']['output']>
  showMapLink?: Maybe<Scalars['Boolean']['output']>
  /** Lot square feet, might be 0 */
  sqftLot: Scalars['SafeInt']['output']
  /** Total square feet, might be 0 */
  sqftTotal: Scalars['Int']['output']
  status: Scalars['String']['output']
  thumbnailImageFileNames?: Maybe<Array<Scalars['String']['output']>>
}

/** Listing Enriched object */
export type ListingEnriched = {
  __typename?: 'ListingEnriched'
  assumableMortgageLoanInfo?: Maybe<ListingLoanInfo>
  assumableMortgageMonthlyHomeExpenses?: Maybe<BucketedMonthlyHomeExpenses>
  assumableMortgageSecondLoanInfo?: Maybe<ListingLoanInfo>
  bucketedMonthlyHomeExpenses?: Maybe<BucketedMonthlyHomeExpenses>
  listing: Listing
  loanInfo?: Maybe<ListingLoanInfo>
  marketReport?: Maybe<ListingMarketReport>
  shareUrl?: Maybe<Scalars['String']['output']>
  similarCount: Scalars['Int']['output']
  similarListings?: Maybe<Array<ListingCompact>>
  /**
   * The last 3 fields
   * native request                         - returned
   * CFE request: native user exists        - returned
   *              native user doesn't exist - don't returned
   */
  smartMove?: Maybe<SmartMove>
}

/** Listing Enriched Options */
export type ListingEnrichedOptions = {
  /** Optional, if provided overrides the default downpayment amount */
  downpaymentAmountDollars?: InputMaybe<Scalars['SafeInt']['input']>
  /** Optional, if provided allows to fetch similar listings in parallel, substantially improving performance */
  priceCents?: InputMaybe<Scalars['SafeInt']['input']>
  /** Optional, if provided allows to fetch similar listings in parallel, substantially improving performance */
  zipCode?: InputMaybe<Scalars['String']['input']>
}

/** Listing Loan Info */
export type ListingLoanInfo = {
  __typename?: 'ListingLoanInfo'
  downpayment: Scalars['Float']['output']
  interestRatePercent: Scalars['Float']['output']
  loanAmount: Scalars['Float']['output']
  termYears: Scalars['Int']['output']
}

/** Market Report for Listing response */
export type ListingMarketReport = {
  __typename?: 'ListingMarketReport'
  allHomesTab: MarketReport
  pricedAroundTab: MarketReport
  /** TODO rename to zipCode */
  zipcode: Scalars['String']['output']
}

/**
 * Subset of MessageTopicKey enum
 * Doesn't enforced by gql, but resolver will validate that only valid values are passed
 * NOTE: We need to manually keep this in sync with MessageTopicKey
 */
export enum ListingMessageTopicKey {
  ListingsDirectMessage = 'listings_direct_message',
  ListingsDirectMessageToListingAgent = 'listings_direct_message_to_listing_agent',
  ListingsDirectMessageToSponsor = 'listings_direct_message_to_sponsor',
  /** Sent to Listing Agent, cc to Customer */
  ListingsOpenHouseRequest = 'listings_open_house_request',
  ListingsPrequalRequest = 'listings_prequal_request',
  ListingsShowingRequest = 'listings_showing_request',
  ListingsShowingRequestToListingAgent = 'listings_showing_request_to_listing_agent'
}

/** Filters for ListingsForArea */
export type ListingsFilters = {
  hasOpenHouses?: InputMaybe<Scalars['Boolean']['input']>
  hasSellerConcession?: InputMaybe<Scalars['Boolean']['input']>
  isAssumable?: InputMaybe<Scalars['Boolean']['input']>
  maxAcres?: InputMaybe<Scalars['Float']['input']>
  maxPrice?: InputMaybe<Scalars['Float']['input']>
  maxSqft?: InputMaybe<Scalars['Float']['input']>
  maxYearBuilt?: InputMaybe<Scalars['Int']['input']>
  minAcres?: InputMaybe<Scalars['Float']['input']>
  minBathroomsCount?: InputMaybe<Scalars['Int']['input']>
  minBedroomsCount?: InputMaybe<Scalars['Int']['input']>
  minPrice?: InputMaybe<Scalars['Float']['input']>
  minSqft?: InputMaybe<Scalars['Float']['input']>
  minYearBuilt?: InputMaybe<Scalars['Int']['input']>
  monthlyExpensesInput?: InputMaybe<MonthlyExpensesInput>
  propertyTypes?: InputMaybe<Array<PropertyType>>
  /**
   * This is enum:
   *   "Active Under Contract"
   *   "Closed"
   *   "Pending"
   * Resolver will validate that only valid statuses are passed
   */
  statuses?: InputMaybe<Array<Scalars['String']['input']>>
}

/** Filters for ListingsForArea */
export type ListingsFiltersOutput = {
  __typename?: 'ListingsFiltersOutput'
  hasOpenHouses?: Maybe<Scalars['Boolean']['output']>
  hasSellerConcession?: Maybe<Scalars['Boolean']['output']>
  isAssumable?: Maybe<Scalars['Boolean']['output']>
  maxAcres?: Maybe<Scalars['Float']['output']>
  maxPrice?: Maybe<Scalars['Float']['output']>
  maxSqft?: Maybe<Scalars['Float']['output']>
  maxYearBuilt?: Maybe<Scalars['Int']['output']>
  minAcres?: Maybe<Scalars['Float']['output']>
  minBathroomsCount?: Maybe<Scalars['Int']['output']>
  minBedroomsCount?: Maybe<Scalars['Int']['output']>
  minPrice?: Maybe<Scalars['Float']['output']>
  minSqft?: Maybe<Scalars['Float']['output']>
  minYearBuilt?: Maybe<Scalars['Int']['output']>
  /**
   * The name is confusing. It's in fact output, but:
   * returned value will be used as input for MonthlyExpensesInput
   */
  monthlyExpensesInput?: Maybe<MonthlyExpenses>
  propertyTypes?: Maybe<Array<PropertyType>>
  statuses?: Maybe<Array<Scalars['String']['output']>>
}

/** Listings for Area response */
export type ListingsForArea = {
  __typename?: 'ListingsForArea'
  /** Whether there are more pages */
  hasMore: Scalars['Boolean']['output']
  /** Array of compact listings, might be empty */
  listings: Array<ListingCompact>
  /** Location of the search */
  location?: Maybe<GeoArea>
  /** Current page, the same as in the input */
  page: Scalars['Int']['output']
  /**
   * Total count of listings, might be 0
   * @deprecated Use hasMore
   */
  totalCount: Scalars['Int']['output']
}

/** Order by for ListingsForArea */
export enum ListingsOrderBy {
  Acres = 'acres',
  /** Always sorted ASC */
  DaysOnMarket = 'daysOnMarket',
  /**
   * Only available when filtering by monthly expenses.
   * In other case will be substituted with `daysOnMarket`.
   */
  MonthlyExpenses = 'monthlyExpenses',
  Price = 'price',
  Sqft = 'sqft'
}

/** Order direction for ListingsForArea */
export enum ListingsOrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** Sort order for ListingsForArea */
export type ListingsSortOrder = {
  orderBy: ListingsOrderBy
  orderDir: ListingsOrderDirection
}

/** Sort order for ListingsForArea */
export type ListingsSortOrderOutput = {
  __typename?: 'ListingsSortOrderOutput'
  orderBy: ListingsOrderBy
  orderDir: ListingsOrderDirection
}

/** Market History Entry */
export type MarketHistoryEntry = {
  __typename?: 'MarketHistoryEntry'
  date: Scalars['String']['output']
  score: Scalars['Float']['output']
}

/** Market Report response */
export type MarketReport = {
  __typename?: 'MarketReport'
  averageBaths: Scalars['Float']['output']
  averageBeds: Scalars['Float']['output']
  averageSqft: Scalars['Float']['output']
  daysOnMarket: Scalars['Int']['output']
  inventoryTotal: Scalars['Int']['output']
  marketHistory: Array<MarketHistoryEntry>
  priceMax?: Maybe<Scalars['Float']['output']>
  priceMin?: Maybe<Scalars['Float']['output']>
}

/** Market Report for Zip */
export type MarketReportZip = {
  __typename?: 'MarketReportZip'
  allHomesTab: MarketReport
  equitySmartSearch?: Maybe<EquitySmartSearch>
  pricedAroundTab: MarketReport
  zipcode: Scalars['String']['output']
}

/** Message Input */
export type MessageInput = {
  /** Required only for Native so the right client record can be found in Mikasa */
  clientId?: InputMaybe<Scalars['String']['input']>
  /** Required for listings_showing_request */
  extraMessage?: InputMaybe<Scalars['String']['input']>
  /** Required for Home Related Messages */
  homeId?: InputMaybe<Scalars['String']['input']>
  /**
   * Required for some listing related messages:
   *
   *   - MessageType.ListingOpenHouseRequest
   *   - MessageType.ListingShowingRequest
   */
  listingId?: InputMaybe<Scalars['ID']['input']>
  /** Ignored for open house request */
  message: Scalars['String']['input']
  /** Required for `listings_open_house_request` */
  openHouseId?: InputMaybe<Scalars['ID']['input']>
  /** Required for listings_showing_request */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Optional for listings_to_listing_agent */
  scheduleTour?: InputMaybe<ScheduleTour>
  /**
   * Relevant to "listing_to_listing_agent" only.
   *
   * Indicates whether the message or tour request should be sent to the preferred Agent instead of Listing Agent.
   *
   * - `true`: Sends the message to the preferred Agent, or to the Listing Agent if no preferred Agent is set.
   * - `false`: Sends the message to the Listing Agent.
   */
  sendToPreferredAgent?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Required for home related and buyer messages
   * This is enum, but can't type it here
   */
  topic?: InputMaybe<Scalars['String']['input']>
  topicKey: MessageTopicKey
}

/**  This enum doesn't follow the naming convention because values are used in the mikasa API  */
export enum MessageTopicKey {
  /** Home Related Messages */
  BuyerAffordabilityToCustomer = 'buyer_affordability_to_customer',
  BuyerAssumableInquiryToLoCustomer = 'buyer_assumable_inquiry_to_lo_customer',
  BuyerAssumableInquiryToSponsorWithReaNotified = 'buyer_assumable_inquiry_to_sponsor_with_rea_notified',
  BuyerAssumableInquiryToUnsponsoredRea = 'buyer_assumable_inquiry_to_unsponsored_rea',
  /** Buyer Related Messages, the list in not complete */
  BuyerGeneralInquiryToCustomer = 'buyer_general_inquiry_to_customer',
  BuyingDreamHomeToCustomerWithNotify = 'buying_dream_home_to_customer_with_notify',
  EquitySmartSearchToSponsor = 'equity_smart_search_to_sponsor',
  HomeAppreciationToCustomer = 'home_appreciation_to_customer',
  HomeImprovementsToSponsor = 'home_improvements_to_sponsor',
  HomeInsuranceToCustomer = 'home_insurance_to_customer',
  HomeValueToSponsor = 'home_value_to_sponsor',
  HomeownerAddSpaceToSponsor = 'homeowner_add_space_to_sponsor',
  HomeownerCashPositionToCustomerWithNotify = 'homeowner_cash_position_to_customer_with_notify',
  HomeownerCashoutOptionsToSponsor = 'homeowner_cashout_options_to_sponsor',
  HomeownerCreditCardDebtToSponsor = 'homeowner_credit_card_debt_to_sponsor',
  HomeownerEmailUpdateToCustomer = 'homeowner_email_update_to_customer',
  HomeownerEmergencyFundToSponsor = 'homeowner_emergency_fund_to_sponsor',
  HomeownerEnergyImprovementsToSponsor = 'homeowner_energy_improvements_to_sponsor',
  HomeownerInvestmentsToSponsor = 'homeowner_investments_to_sponsor',
  HomeownerMarketToCustomer = 'homeowner_market_to_customer',
  HomeownerShortTermRentalsToCustomer = 'homeowner_short_term_rentals_to_customer',
  HomeownerShouldSellMarketToCustomer = 'homeowner_should_sell_market_to_customer',
  HomeownerShouldSellToCustomer = 'homeowner_should_sell_to_customer',
  HomeownerStudentDebtToSponsor = 'homeowner_student_debt_to_sponsor',
  InvestmentPropertyToCustomerWithNotify = 'investment_property_to_customer_with_notify',
  /** Listing Related Messages */
  ListingsDirectMessage = 'listings_direct_message',
  ListingsDirectMessageToListingAgent = 'listings_direct_message_to_listing_agent',
  ListingsDirectMessageToSponsor = 'listings_direct_message_to_sponsor',
  /** Sent to Listing Agent, cc to Customer */
  ListingsOpenHouseRequest = 'listings_open_house_request',
  ListingsPrequalRequest = 'listings_prequal_request',
  ListingsShowingRequest = 'listings_showing_request',
  ListingsShowingRequestToListingAgent = 'listings_showing_request_to_listing_agent',
  MortgageCoachToCustomer = 'mortgage_coach_to_customer',
  MortgageInsuranceToSponsor = 'mortgage_insurance_to_sponsor',
  PrincipalPaymentsToSponsor = 'principal_payments_to_sponsor',
  PurchasingPowerToCustomer = 'purchasing_power_to_customer',
  RefinancingToSponsor = 'refinancing_to_sponsor',
  ReverseMortgageToSponsor = 'reverse_mortgage_to_sponsor',
  SecondHomeToCustomerWithNotify = 'second_home_to_customer_with_notify',
  SellingHomeToCustomerWithNotify = 'selling_home_to_customer_with_notify',
  UsingEquityToSponsor = 'using_equity_to_sponsor',
  VideoEmailToSponsor = 'video_email_to_sponsor',
  WhatToSellForToCustomer = 'what_to_sell_for_to_customer'
}

/** Milestone object */
export type Milestone = {
  __typename?: 'Milestone'
  /** buyingPowerIncrease - the amount of buying power that the user will gain after this milestone from the previous milestone */
  buyingPowerIncrease: Scalars['Float']['output']
  buyingPowerResult?: Maybe<BuyingPower>
  /** creditScore - The credit score at the end of this milestone */
  creditScore: Scalars['Int']['output']
  /** creditScoreIncrease - The amount the user should increase their credit score to achieve this milestone */
  creditScoreIncrease: Scalars['Int']['output']
  /**
   * debtsToRepay - Hash of account number of the debts that should be repaid by the end of this
   * milestone, including the debts in debtsToRepayChange.
   */
  debtsToRepay: Array<Scalars['String']['output']>
  /**
   * debtsToRepayChange - Hash of the account number of the debts that should be repaid
   * between the last milestone and this one.
   */
  debtsToRepayChange?: Maybe<Array<Scalars['String']['output']>>
  /** debtsToRepayChangeTotalCost - The total cost of all debts in debtsToRepayChange. */
  debtsToRepayChangeCost: Scalars['Float']['output']
  /** debtsToRepayTotalCost - The total cost of all debts in debtsToRepay. */
  debtsToRepayTotalCost: Scalars['Float']['output']
  /**
   * estimatedMonthsToComplete - the estimated number of months to complete this milestone
   * from the previous milestone.
   */
  estimatedMonthsToComplete: Scalars['Int']['output']
  /** months to milestone from start */
  months: Scalars['Int']['output']
  programName: Scalars['String']['output']
  /** projectedBuyingPower - the buying power that the user would reach once they achieve this milestone */
  projectedBuyingPower: Scalars['Float']['output']
  /**
   * savings - The ending value of this user's total savings after this milestone
   * NOTE: If a milestone contains a debt payoff, it is possible that cash
   * increase is positive while the savings number here is LESS THAN the previous
   * milestone. This indicates the user needs to save the totalCashIncrease amount
   * to be able to afford the debt payoff, which will then decrease the users
   * total savings to the value held in savings.
   */
  savings: Scalars['Float']['output']
  /**
   * totalCashIncrease - the total amount of money the user should save to achieve this milestone,
   * including any money that goes straight to debt payoff for this milestone.
   */
  totalCashIncrease: Scalars['Float']['output']
  type?: Maybe<MilestoneTypes>
}

/** Milestone Types */
export enum MilestoneTypes {
  ImproveFinances = 'ImproveFinances'
}

/** Monthly expensed output */
export type MonthlyExpenses = {
  __typename?: 'MonthlyExpenses'
  /** Downpayment Amount in dollars */
  downpaymentAmountDollars: Scalars['SafeInt']['output']
  interestRatePercent?: Maybe<Scalars['Float']['output']>
  /** Maximum total monthly expenses limit in dollars */
  maxMonthlyExpenses?: Maybe<Scalars['Int']['output']>
  /** Minimum total monthly expenses in dollars */
  minMonthlyExpenses?: Maybe<Scalars['Int']['output']>
}

/**
 * Not a traditional filter.
 * In addition to min/max values, it requires downpayment and interest rate to
 * calculate monthly expenses.
 */
export type MonthlyExpensesInput = {
  /** Downpayment Amount in dollars */
  downpaymentAmountDollars: Scalars['SafeInt']['input']
  /** Annual interest rate, %. Optional, default to DEFAULT_CURRENT_RATE * 100 (7.125%) */
  interestRatePercent?: InputMaybe<Scalars['Float']['input']>
  /** Maximum total monthly expenses limit in dollars. Optional, default to DEFAULT_MAX_MONTHLY_EXPENSES (100_000) */
  maxMonthlyExpenses?: InputMaybe<Scalars['Int']['input']>
  /** Minimum total monthly expenses in dollars. Optional, default to 0 */
  minMonthlyExpenses?: InputMaybe<Scalars['Int']['input']>
}

/** Top level mutation object */
export type Mutation = {
  __typename?: 'Mutation'
  /** Disabled in prod */
  aiChat: AiChat
  /**
   * Change feed location, only googlePlaceId is accepted, other location props are fetched from Google Places API
   * @deprecated Use changeFeedsLocation to just change all of the feed locations at once instead of one at a time
   */
  changeFeedLocation: FeedBase
  /** Change all feeds locations, only googlePlaceId is accepted, other location props are fetched from Google Places API */
  changeFeedsLocation: Array<FeedBase>
  /** Create a client event */
  createClientEvent: Scalars['Boolean']['output']
  /**
   * Create a new feed
   * @deprecated Pass googlePlaceId into feeds() query instead to automatically create missing feeds
   */
  createFeed: CreateFeedResponse
  /** Impersonation intentionally not supported */
  createSavedSearch: CreateSavedSearchResult
  /** Impersonation intentionally not supported */
  deleteSavedSearch: Scalars['Boolean']['output']
  /** Complete client's chat session, session meta and session data will be deleted */
  endChatSession: Scalars['Boolean']['output']
  /** ClientId required for Native */
  resetFavoriteListingsAlertCadence: ClientsFavoriteListings
  /** Send Buyer, Home, or Listing Message */
  sendMessage: Scalars['Boolean']['output']
  /** Toggles a Listing between the 'favorited' and 'not favorited' states */
  toggleFavoriteListing: ToggleFavoriteListingResponse
  /** Toggle Feed subscription between the 'subscribed' and 'not subscribed' states */
  toggleFeedSubscribed: ToggleFeedSubscribedResponse
  updateClient: Client
  /** Impersonation intentionally not supported */
  updateFavoriteListing: FavoriteListing
  /** Update home record, there can be a lot more fields, but only these for now */
  updateHome: Home
  /** Update preferred agent */
  updatePreferredAgent: PreferredAgent
  /**
   * Expect a complete Saved Search object
   * Impersonation intentionally not supported
   */
  updateSavedSearch: SavedSearch
  /**
   * Dedicated resolver to update Saved Search Alert Cadence only
   * Impersonation intentionally not supported
   */
  updateSavedSearchAlertCadence: SavedSearch
}

/** Top level mutation object */
export type MutationAiChatArgs = {
  input: AiChatInput
}

/** Top level mutation object */
export type MutationChangeFeedLocationArgs = {
  feedId: Scalars['ID']['input']
  googlePlaceId: Scalars['String']['input']
}

/** Top level mutation object */
export type MutationChangeFeedsLocationArgs = {
  feedIds: Array<Scalars['ID']['input']>
  googlePlaceId: Scalars['String']['input']
}

/** Top level mutation object */
export type MutationCreateClientEventArgs = {
  clientEventInput: ClientEventInput
}

/** Top level mutation object */
export type MutationCreateFeedArgs = {
  feedInput: FeedInput
}

/** Top level mutation object */
export type MutationCreateSavedSearchArgs = {
  input: SavedSearchInput
}

/** Top level mutation object */
export type MutationDeleteSavedSearchArgs = {
  id: Scalars['ID']['input']
}

/** Top level mutation object */
export type MutationResetFavoriteListingsAlertCadenceArgs = {
  clientId: Scalars['ID']['input']
}

/** Top level mutation object */
export type MutationSendMessageArgs = {
  input: MessageInput
}

/** Top level mutation object */
export type MutationToggleFavoriteListingArgs = {
  toggleFavoriteListingInput?: InputMaybe<ToggleFavoriteListingInput>
}

/** Top level mutation object */
export type MutationToggleFeedSubscribedArgs = {
  feedId: Scalars['ID']['input']
}

/** Top level mutation object */
export type MutationUpdateClientArgs = {
  input: UpdateClientInput
}

/** Top level mutation object */
export type MutationUpdateFavoriteListingArgs = {
  input: UpdateFavoriteListingInput
  listingId: Scalars['ID']['input']
}

/** Top level mutation object */
export type MutationUpdateHomeArgs = {
  input: UpdateHomeInput
}

/** Top level mutation object */
export type MutationUpdatePreferredAgentArgs = {
  input: UpdatePreferredAgentInput
}

/** Top level mutation object */
export type MutationUpdateSavedSearchArgs = {
  id: Scalars['ID']['input']
  input: SavedSearchInput
}

/** Top level mutation object */
export type MutationUpdateSavedSearchAlertCadenceArgs = {
  id: Scalars['ID']['input']
  input: SavedSearchAlertCadenceInput
}

/** Native App Config */
export type NativeAppConfig = {
  __typename?: 'NativeAppConfig'
  /** Minimum version of the app */
  minVersion: Scalars['String']['output']
}

/** Open House Object */
export type OpenHouse = {
  __typename?: 'OpenHouse'
  directions?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  endTime: Scalars['String']['output']
  id: Scalars['ID']['output']
  refreshments?: Maybe<Scalars['String']['output']>
  remarks?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  startTime: Scalars['String']['output']
}

/** placeIdInput object */
export type PlaceIdInput = {
  placeId: Scalars['String']['input']
}

/** PlaceId Object */
export type PlaceIdOutput = {
  __typename?: 'PlaceIdOutput'
  placeId: Scalars['String']['output']
}

/** Preferred Agent */
export type PreferredAgent = {
  __typename?: 'PreferredAgent'
  agentEmail?: Maybe<Scalars['String']['output']>
  agentFirstName?: Maybe<Scalars['String']['output']>
  agentLastName?: Maybe<Scalars['String']['output']>
  clientId: Scalars['ID']['output']
}

/** Preferred Agent Input */
export type PreferredAgentInput = {
  /** Required when Customer impersonates Client */
  clientId?: InputMaybe<Scalars['ID']['input']>
}

/**
 * Property types for filters.
 * We map it to PropertySubType and query by PROPERTY_SUB_TYPE in Hasura.
 */
export enum PropertyType {
  Condo = 'Condo',
  House = 'House',
  /** The value was 'Lots/Land', native app shall update it! */
  LotsLand = 'LotsLand',
  Manufactured = 'Manufactured',
  Other = 'Other',
  Townhome = 'Townhome'
}

/** Top level query object */
export type Query = {
  __typename?: 'Query'
  /** Return the History of Active AI Chat */
  aiChatHistory?: Maybe<AiChatHistory>
  /** Get client data */
  client: Client
  /**
   * Ephemeral Feeds.
   * FeedBase is not stored in the database, created on the fly.
   * Currently only Recently Sold for client's zip code.
   */
  ephemeralFeeds: Array<EphemeralFeed>
  /** Get the favorite listings of the client. clientId is optional for impersonation queries */
  favoriteListings: FavoriteListings
  /** Get a feed by ID, metadata and compact listings */
  feed: Feed
  /**
   * Get all user feeds, metadata only
   * Create any missing feeds for the user
   * googlePlace is required to create any of these missing feeds
   * Assumables feed is based on feature flag
   * RecentlySold is based on the location not being in a non disclosure state
   */
  feeds: Array<Maybe<FeedBase>>
  /**
   * Retrieve a listing by ID and return an enriched listing.
   *
   * Handles both authenticated and unauthenticated users:
   *   unauthenticated - returns the listing and similar listings,
   *   authenticated - returns additional user-specific data.
   */
  listingEnriched: ListingEnriched
  /**
   * Listings for Area.
   * Authentication is not required.
   */
  listingsForArea: ListingsForArea
  /** Native App Config, no auth required */
  nativeAppConfig: NativeAppConfig
  /**
   * Get the preferred agent for the Client.
   * `clientId` is required when Customer impersonates Client.
   */
  preferredAgent: PreferredAgent
  /**
   * Impersonation is supported.
   * `clientId` is required when Customer impersonates Client.
   * Add it to the `input`
   */
  savedSearches: Array<Maybe<SavedSearch>>
  /**
   * Test resolver.
   * **NOTE**: Do not modify signature unless it's really necessary.
   * Otherwise, it will trigger codegen in the frontend.
   */
  testResolver: Scalars['String']['output']
}

/** Top level query object */
export type QueryClientArgs = {
  clientId: Scalars['ID']['input']
}

/** Top level query object */
export type QueryEphemeralFeedsArgs = {
  input: EphemeralFeedsInput
}

/** Top level query object */
export type QueryFavoriteListingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

/** Top level query object */
export type QueryFeedArgs = {
  feedId: Scalars['ID']['input']
  options?: InputMaybe<FeedOptions>
}

/** Top level query object */
export type QueryFeedsArgs = {
  googlePlaceId?: InputMaybe<Scalars['String']['input']>
  options?: InputMaybe<FeedOptions>
  webUserId?: InputMaybe<Scalars['ID']['input']>
}

/** Top level query object */
export type QueryListingEnrichedArgs = {
  listingId: Scalars['ID']['input']
  options?: InputMaybe<ListingEnrichedOptions>
}

/** Top level query object */
export type QueryListingsForAreaArgs = {
  filters: ListingsFilters
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
  savedSearchId?: InputMaybe<Scalars['ID']['input']>
  searchArea: SearchArea
  sortOrder: ListingsSortOrder
}

/** Top level query object */
export type QueryPreferredAgentArgs = {
  input?: InputMaybe<PreferredAgentInput>
}

/** Top level query object */
export type QuerySavedSearchesArgs = {
  input: SavedSearchesInput
}

/** Top level query object */
export type QueryTestResolverArgs = {
  input: TestResolverInput
}

/** Saved Search */
export type SavedSearch = {
  __typename?: 'SavedSearch'
  /**
   * In days, possible values: 1, 7
   * Will be null if Alert is not set
   */
  alertCadenceDays?: Maybe<Scalars['Int']['output']>
  clientId: Scalars['String']['output']
  /** ISO date string */
  createdAt: Scalars['String']['output']
  filters: ListingsFiltersOutput
  id: Scalars['ID']['output']
  /** ISO date string */
  lastNotificationAtNative?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastNotificationAtWeb?: Maybe<Scalars['String']['output']>
  /** ISO date string */
  lastSeenAt?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  searchArea: SearchAreaOutput
  sortOrder: ListingsSortOrderOutput
  /** ISO date string */
  subscribedAt?: Maybe<Scalars['String']['output']>
}

/** Saved Search Alert Cadence Input */
export type SavedSearchAlertCadenceInput = {
  /**
   * In days, allowed values: 1, 7
   * If empty, no Alert will be sent
   */
  alertCadenceDays?: InputMaybe<Scalars['Int']['input']>
}

/**
 * Saved Search Input
 * The same for Create and Update mutations.
 * Create and Update impersonation is intensionally not supported.
 */
export type SavedSearchInput = {
  /**
   * In days, allowed values: 1, 7
   * If empty, no Alert will be sent
   */
  alertCadenceDays?: InputMaybe<Scalars['Int']['input']>
  /** Filters for listings, all are optional */
  filters: ListingsFilters
  /** Name of the saved search */
  name: Scalars['String']['input']
  searchArea: SearchArea
  sortOrder: ListingsSortOrder
}

/** Saved Searches Input */
export type SavedSearchesInput = {
  /** Required when Customer impersonates Client */
  clientId?: InputMaybe<Scalars['ID']['input']>
}

/** Schedule Tour, optional for listings_to_listing_agent */
export type ScheduleTour = {
  inPerson?: InputMaybe<Scalars['Boolean']['input']>
  tourDate?: InputMaybe<Scalars['String']['input']>
}

/** Search Area Input */
export type SearchArea = {
  /**
   * Either boundingBox, geoArea, or placeIdInput must be provided
   * Resolver will validate that only one of them is passed
   */
  boundingBox?: InputMaybe<BoundingBoxInput>
  geoArea?: InputMaybe<GeoAreaInput>
  placeIdInput?: InputMaybe<PlaceIdInput>
}

/** Search Area Output */
export type SearchAreaOutput = {
  __typename?: 'SearchAreaOutput'
  /** Either boundingBox, geoArea, or placeIdInput will be returned */
  boundingBox?: Maybe<BoundingBox>
  geoArea?: Maybe<GeoArea>
  placeIdInput?: Maybe<PlaceIdOutput>
}

/** Not used anywhere, in the BE, might used in the FE */
export enum SearchLevel {
  /** Used only in listingsForArea input */
  BoundingBox = 'bounding_box',
  City = 'city',
  PostalCode = 'postal_code'
}

/** Smart Move response */
export type SmartMove = {
  __typename?: 'SmartMove'
  creditIncrease: Scalars['Float']['output']
  debtPayOffAmount: Scalars['Float']['output']
  /** false means the user has enough savings to buy the home outright */
  isLoanRequired: Scalars['Boolean']['output']
  maxPurchasingPower?: Maybe<Scalars['Float']['output']>
  milestones?: Maybe<Array<Milestone>>
  monthsToReach: Scalars['Float']['output']
  preferredMonthlyHousingPayment: Scalars['Float']['output']
  purchasingPower: Scalars['Float']['output']
  savingsIncrease: Scalars['Float']['output']
}

/** Smart Search Bracket */
export type SmartSearchBracket = {
  __typename?: 'SmartSearchBracket'
  cashAfterSelling: Scalars['Float']['output']
  downPayment: Scalars['Float']['output']
  homeValue: Scalars['Float']['output']
  loanBalance: Scalars['Float']['output']
  mortgagePaymentDifference: Scalars['Float']['output']
  percentDown: Scalars['Float']['output']
  pocketAmount: Scalars['Float']['output']
  purchasePrice: Scalars['Float']['output']
}

/**
 * Test resolver input
 * All fields are optional
 */
export type TestResolverInput = {
  number1?: InputMaybe<Scalars['Int']['input']>
  number2?: InputMaybe<Scalars['Int']['input']>
  string1?: InputMaybe<Scalars['String']['input']>
  string2?: InputMaybe<Scalars['String']['input']>
}

/** Toggle Favorite Property */
export type ToggleFavoriteListingInput = {
  eventSource?: InputMaybe<ClientEventSource>
  listingId: Scalars['ID']['input']
}

/** ToggleFeedSubscribedResponse */
export type ToggleFavoriteListingResponse = {
  __typename?: 'ToggleFavoriteListingResponse'
  isFavorite: Scalars['Boolean']['output']
}

/** ToggleFeedSubscribed response */
export type ToggleFeedSubscribedResponse = {
  __typename?: 'ToggleFeedSubscribedResponse'
  feedId: Scalars['ID']['output']
  subscribed: Scalars['Boolean']['output']
}

/** Update Client Input - can be a lot more fields but only this for now */
export type UpdateClientInput = {
  favoriteListingsAlertCadenceDays?: InputMaybe<Scalars['Int']['input']>
}

/**
 * Update Favorite Listing Input
 * Impersonation is intensionally not supported.
 */
export type UpdateFavoriteListingInput = {
  /**
   * In days, allowed values: -1/never, 1/daily, 7/weekly
   * If empty, Alert will be null - will revert to default
   */
  alertCadenceDays?: InputMaybe<Scalars['Int']['input']>
}

/** Update Home Input - Can be a lot more but only these for now */
export type UpdateHomeInput = {
  /** Used for CMA request */
  hasRequestedCma: Scalars['String']['input']
  /** home UUID */
  homeId: Scalars['String']['input']
}

/** Update Preferred Agent Input */
export type UpdatePreferredAgentInput = {
  /** Agent email, the only required field */
  agentEmail: Scalars['String']['input']
  /** Set to null to remove the value */
  agentFirstName?: InputMaybe<Scalars['String']['input']>
  /** Set to null to remove the value */
  agentLastName?: InputMaybe<Scalars['String']['input']>
  /** Required when Customer impersonates Client */
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type ChangeFeedLocationMutationVariables = Exact<{
  feedId: Scalars['ID']['input']
  googlePlaceId: Scalars['String']['input']
}>

export type ChangeFeedLocationMutation = {
  __typename?: 'Mutation'
  changeFeedLocation: { __typename?: 'FeedBase'; id: string; feedType: FeedType }
}

export type ChangeFeedsLocationMutationVariables = Exact<{
  feedIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  googlePlaceId: Scalars['String']['input']
}>

export type ChangeFeedsLocationMutation = {
  __typename?: 'Mutation'
  changeFeedsLocation: Array<{ __typename?: 'FeedBase'; id: string; feedType: FeedType }>
}

export type CreateSavedSearchMutationVariables = Exact<{
  input: SavedSearchInput
}>

export type CreateSavedSearchMutation = {
  __typename?: 'Mutation'
  createSavedSearch: { __typename?: 'CreateSavedSearchResult'; id: string }
}

export type DeleteSavedSearchMutationVariables = Exact<{
  deleteSavedSearchId: Scalars['ID']['input']
}>

export type DeleteSavedSearchMutation = { __typename?: 'Mutation'; deleteSavedSearch: boolean }

export type FavoriteListingsQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type FavoriteListingsQuery = {
  __typename?: 'Query'
  favoriteListings: {
    __typename?: 'FavoriteListings'
    listings: Array<{
      __typename?: 'Listing'
      id: string
      alertCadenceDays?: number | null
      description?: string | null
      disclaimer: string
      geohash?: string | null
      imageCoverUrl: string
      mlsLogo?: string | null
      mlsNumber: string
      originatingSystemName?: string | null
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      propertyType: string
      propertySubtype?: string | null
      bedroomsCount?: number | null
      bathroomsCount?: number | null
      sqftTotal: number
      sqftLot: number
      showMapLink?: boolean | null
      status: string
      thumbnailImageFileNames: Array<string>
      updatedAt: string
      yearBuilt?: number | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      agent?: {
        __typename?: 'ListingAgent'
        email?: string | null
        mlsId?: string | null
        name?: string | null
        phone?: string | null
        stateLicenseState?: string | null
        stateLicenseNumber?: string | null
      } | null
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
    } | null>
  }
}

export type FeedQueryVariables = Exact<{
  feedId: Scalars['ID']['input']
  options?: InputMaybe<FeedOptions>
}>

export type FeedQuery = {
  __typename?: 'Query'
  feed: {
    __typename?: 'Feed'
    feedType: FeedType
    id: string
    homeId?: string | null
    lastNotificationAtNative?: string | null
    lastNotificationAtWeb?: string | null
    subscribedAt?: string | null
    subscribed: boolean
    webUserId: string
    listings: Array<{
      __typename?: 'ListingCompact'
      assumableRatePercent?: number | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      geohash?: string | null
      id: string
      listing_url?: string | null
      mlsNumber: string
      originatingSystemName: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      rank?: number | null
      showMapLink?: boolean | null
      sqftTotal: number
      sqftLot: number
      status: string
      propertyType: string
      isAssumable: boolean
      hasSellerConcession?: boolean | null
      thumbnailImageFileNames?: Array<string> | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
    } | null>
    location: {
      __typename?: 'GeoArea'
      latitude: number
      longitude: number
      name: string
      placeId: string
      stateAbbreviation: string
      level: GeoAreaLevel
      city?: string | null
      zipCode?: string | null
    }
  }
}

export type EphemeralFeedsQueryVariables = Exact<{
  input: EphemeralFeedsInput
}>

export type EphemeralFeedsQuery = {
  __typename?: 'Query'
  ephemeralFeeds: Array<{
    __typename?: 'EphemeralFeed'
    id: string
    zipCode: string
    listings: Array<{
      __typename?: 'ListingCompact'
      assumableRatePercent?: number | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      complianceBrokerageInfo?: string | null
      daysOnMarket?: number | null
      hasSellerConcession?: boolean | null
      id: string
      internetAddressDisplayYn?: boolean | null
      isAssumable: boolean
      listing_url?: string | null
      mlsLogo?: string | null
      mlsNumber: string
      originatingSystemName: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      propertyType: string
      rank?: number | null
      showMapLink?: boolean | null
      sqftLot: number
      sqftTotal: number
      status: string
      thumbnailImageFileNames?: Array<string> | null
      address: {
        __typename?: 'ListingAddress'
        city?: string | null
        latitude: number
        longitude: number
        number?: string | null
        postalCode: string
        stateOrProvince: string
        street?: string | null
        unitNumber?: string | null
      }
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
      bucketedMonthlyHomeExpenses?: {
        __typename?: 'BucketedMonthlyHomeExpenses'
        homeInsurance: number
        monthlyHoaFees: number
        monthlyPrincipalAndInterest: number
        mortgageInsurance: number
        propertyTaxes: number
        totalMonthlyHomeExpenses: number
      } | null
      loanInfo?: {
        __typename?: 'ListingLoanInfo'
        downpayment: number
        interestRatePercent: number
        loanAmount: number
        termYears: number
      } | null
    } | null>
  }>
}

export type FeedsQueryVariables = Exact<{
  googlePlaceId?: InputMaybe<Scalars['String']['input']>
  webUserId?: InputMaybe<Scalars['ID']['input']>
}>

export type FeedsQuery = {
  __typename?: 'Query'
  feeds: Array<{
    __typename?: 'FeedBase'
    id: string
    homeId?: string | null
    feedType: FeedType
    lastNotificationAtNative?: string | null
    lastNotificationAtWeb?: string | null
    subscribedAt?: string | null
    subscribed: boolean
    webUserId: string
    location: {
      __typename?: 'GeoArea'
      latitude: number
      longitude: number
      name: string
      placeId: string
      stateAbbreviation: string
      level: GeoAreaLevel
      city?: string | null
      zipCode?: string | null
    }
  } | null>
}

export type ListingEnrichedQueryVariables = Exact<{
  listingId: Scalars['ID']['input']
  options?: InputMaybe<ListingEnrichedOptions>
}>

export type ListingEnrichedQuery = {
  __typename?: 'Query'
  listingEnriched: {
    __typename?: 'ListingEnriched'
    similarCount: number
    shareUrl?: string | null
    listing: {
      __typename?: 'Listing'
      id: string
      isAssumable: boolean
      assumableLoanType?: string | null
      assumableLoanBalance?: number | null
      assumableMonthlyPayment?: number | null
      assumableMonthsInHome?: number | null
      assumableNumLoanPayments?: number | null
      assumableRatePercent?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      description?: string | null
      disclaimer: string
      geohash?: string | null
      hasBasement?: boolean | null
      hasFireplace?: boolean | null
      hasGarage?: boolean | null
      hasPool?: boolean | null
      hasSpa?: boolean | null
      hasSellerConcession?: boolean | null
      hasView?: boolean | null
      imageCoverUrl: string
      mlsLogo?: string | null
      mlsNumber: string
      originatingSystemName?: string | null
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      propertyType: string
      propertySubtype?: string | null
      bedroomsCount?: number | null
      bathroomsCount?: number | null
      buyerBrokerageName?: string | null
      sqftLot: number
      sqftTotal: number
      internetAddressDisplayYn?: boolean | null
      showMapLink?: boolean | null
      status: string
      thumbnailImageFileNames: Array<string>
      updatedAt: string
      utilities?: Array<string | null> | null
      yearBuilt?: number | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      agent?: {
        __typename?: 'ListingAgent'
        email?: string | null
        mlsId?: string | null
        name?: string | null
        phone?: string | null
        stateLicenseState?: string | null
        stateLicenseNumber?: string | null
      } | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
    }
    similarListings?: Array<{
      __typename?: 'ListingCompact'
      geohash?: string | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      id: string
      listing_url?: string | null
      mlsNumber: string
      originatingSystemName: string
      propertyType: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      rank?: number | null
      showMapLink?: boolean | null
      internetAddressDisplayYn?: boolean | null
      isAssumable: boolean
      sqftLot: number
      sqftTotal: number
      status: string
      thumbnailImageFileNames?: Array<string> | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
    }> | null
    smartMove?: {
      __typename?: 'SmartMove'
      monthsToReach: number
      savingsIncrease: number
      creditIncrease: number
      debtPayOffAmount: number
      isLoanRequired: boolean
      purchasingPower: number
      preferredMonthlyHousingPayment: number
      milestones?: Array<{
        __typename?: 'Milestone'
        type?: MilestoneTypes | null
        projectedBuyingPower: number
        buyingPowerIncrease: number
        savings: number
        totalCashIncrease: number
        creditScore: number
        creditScoreIncrease: number
        debtsToRepay: Array<string>
        debtsToRepayChange?: Array<string> | null
        debtsToRepayTotalCost: number
        debtsToRepayChangeCost: number
        estimatedMonthsToComplete: number
        programName: string
        months: number
        buyingPowerResult?: {
          __typename?: 'BuyingPower'
          programName: string
          buyingPower: number
          loanAmount: number
          downPayment: number
          reserves: number
          closingCosts: number
          flexFunds: number
          totalMonthlyHomeExpenses: number
          interestRate: number
          qualifies: boolean
          BEDTI: number
          FEDTI: number
          LTV: number
          rejectionReason: string
          constrainedByDTI?: boolean | null
          dpaProgram?: boolean | null
          firstMortgageAmount?: number | null
          secondMortgageAmount?: number | null
          constrainedByPreferredPayment: boolean
        } | null
      }> | null
    } | null
    bucketedMonthlyHomeExpenses?: {
      __typename?: 'BucketedMonthlyHomeExpenses'
      homeInsurance: number
      monthlyPrincipalAndInterest: number
      mortgageInsurance: number
      propertyTaxes: number
      totalMonthlyHomeExpenses: number
      monthlyHoaFees: number
    } | null
    loanInfo?: {
      __typename?: 'ListingLoanInfo'
      loanAmount: number
      downpayment: number
      termYears: number
      interestRatePercent: number
    } | null
    marketReport?: {
      __typename?: 'ListingMarketReport'
      zipcode: string
      allHomesTab: {
        __typename?: 'MarketReport'
        averageBaths: number
        averageBeds: number
        averageSqft: number
        daysOnMarket: number
        inventoryTotal: number
        priceMax?: number | null
        priceMin?: number | null
        marketHistory: Array<{ __typename?: 'MarketHistoryEntry'; date: string; score: number }>
      }
      pricedAroundTab: {
        __typename?: 'MarketReport'
        averageBaths: number
        averageBeds: number
        averageSqft: number
        daysOnMarket: number
        inventoryTotal: number
        priceMax?: number | null
        priceMin?: number | null
        marketHistory: Array<{ __typename?: 'MarketHistoryEntry'; date: string; score: number }>
      }
    } | null
    assumableMortgageLoanInfo?: {
      __typename?: 'ListingLoanInfo'
      downpayment: number
      interestRatePercent: number
      loanAmount: number
      termYears: number
    } | null
    assumableMortgageMonthlyHomeExpenses?: {
      __typename?: 'BucketedMonthlyHomeExpenses'
      homeInsurance: number
      monthlyHoaFees: number
      monthlyPrincipalAndInterest: number
      mortgageInsurance: number
      propertyTaxes: number
      secondMortgagePrincipalAndInterest?: number | null
      totalMonthlyHomeExpenses: number
    } | null
    assumableMortgageSecondLoanInfo?: {
      __typename?: 'ListingLoanInfo'
      downpayment: number
      interestRatePercent: number
      loanAmount: number
      termYears: number
    } | null
  }
}

export type ListingsForAreaQueryVariables = Exact<{
  searchArea: SearchArea
  filters: ListingsFilters
  sortOrder: ListingsSortOrder
  perPage: Scalars['Int']['input']
  page: Scalars['Int']['input']
}>

export type ListingsForAreaQuery = {
  __typename?: 'Query'
  listingsForArea: {
    __typename?: 'ListingsForArea'
    page: number
    hasMore: boolean
    listings: Array<{
      __typename?: 'ListingCompact'
      geohash?: string | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      buyerBrokerageName?: string | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      id: string
      isAssumable: boolean
      hasSellerConcession?: boolean | null
      listing_url?: string | null
      mlsLogo?: string | null
      mlsNumber: string
      originatingSystemName: string
      propertyType: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      rank?: number | null
      showMapLink?: boolean | null
      internetAddressDisplayYn?: boolean | null
      assumableRatePercent?: number | null
      sqftLot: number
      sqftTotal: number
      status: string
      thumbnailImageFileNames?: Array<string> | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
      bucketedMonthlyHomeExpenses?: {
        __typename?: 'BucketedMonthlyHomeExpenses'
        homeInsurance: number
        monthlyPrincipalAndInterest: number
        mortgageInsurance: number
        propertyTaxes: number
        totalMonthlyHomeExpenses: number
        monthlyHoaFees: number
      } | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      loanInfo?: {
        __typename?: 'ListingLoanInfo'
        loanAmount: number
        downpayment: number
        termYears: number
        interestRatePercent: number
      } | null
    }>
    location?: {
      __typename?: 'GeoArea'
      city?: string | null
      latitude: number
      level: GeoAreaLevel
      longitude: number
      name: string
      placeId: string
      stateAbbreviation: string
      zipCode?: string | null
    } | null
  }
}

export type PreferredAgentQueryVariables = Exact<{
  input?: InputMaybe<PreferredAgentInput>
}>

export type PreferredAgentQuery = {
  __typename?: 'Query'
  preferredAgent: {
    __typename?: 'PreferredAgent'
    agentEmail?: string | null
    agentFirstName?: string | null
    agentLastName?: string | null
    clientId: string
  }
}

export type UpdatePreferredAgentMutationVariables = Exact<{
  input: UpdatePreferredAgentInput
}>

export type UpdatePreferredAgentMutation = {
  __typename?: 'Mutation'
  updatePreferredAgent: {
    __typename?: 'PreferredAgent'
    agentEmail?: string | null
    agentFirstName?: string | null
    agentLastName?: string | null
    clientId: string
  }
}

export type ResetFavoriteListingsAlertCadenceMutationVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type ResetFavoriteListingsAlertCadenceMutation = {
  __typename?: 'Mutation'
  resetFavoriteListingsAlertCadence: {
    __typename?: 'ClientsFavoriteListings'
    favoriteListings: Array<{
      __typename?: 'FavoriteListing'
      alertCadenceDays?: number | null
      createdAt?: string | null
      updatedAt?: string | null
      clientId?: string | null
      addressKey?: string | null
    } | null>
  }
}

export type SavedSearchesQueryVariables = Exact<{
  input: SavedSearchesInput
}>

export type SavedSearchesQuery = {
  __typename?: 'Query'
  savedSearches: Array<{
    __typename?: 'SavedSearch'
    alertCadenceDays?: number | null
    clientId: string
    id: string
    lastNotificationAtNative?: string | null
    lastNotificationAtWeb?: string | null
    lastSeenAt?: string | null
    name: string
    subscribedAt?: string | null
    filters: {
      __typename?: 'ListingsFiltersOutput'
      hasOpenHouses?: boolean | null
      hasSellerConcession?: boolean | null
      isAssumable?: boolean | null
      maxAcres?: number | null
      maxPrice?: number | null
      maxSqft?: number | null
      maxYearBuilt?: number | null
      minAcres?: number | null
      minBathroomsCount?: number | null
      minBedroomsCount?: number | null
      minPrice?: number | null
      minSqft?: number | null
      minYearBuilt?: number | null
      propertyTypes?: Array<PropertyType> | null
      statuses?: Array<string> | null
      monthlyExpensesInput?: {
        __typename?: 'MonthlyExpenses'
        downpaymentAmountDollars: number
        interestRatePercent?: number | null
        maxMonthlyExpenses?: number | null
        minMonthlyExpenses?: number | null
      } | null
    }
    searchArea: {
      __typename?: 'SearchAreaOutput'
      boundingBox?: {
        __typename?: 'BoundingBox'
        level: BoundingBoxLevel
        bottomRight: { __typename?: 'CoordinatesOutput'; latitude: number; longitude: number }
        topLeft: { __typename?: 'CoordinatesOutput'; latitude: number; longitude: number }
      } | null
      geoArea?: {
        __typename?: 'GeoArea'
        city?: string | null
        latitude: number
        level: GeoAreaLevel
        longitude: number
        name: string
        placeId: string
        stateAbbreviation: string
        zipCode?: string | null
      } | null
      placeIdInput?: { __typename?: 'PlaceIdOutput'; placeId: string } | null
    }
    sortOrder: { __typename?: 'ListingsSortOrderOutput'; orderBy: ListingsOrderBy; orderDir: ListingsOrderDirection }
  } | null>
}

export type SendMessageMutationVariables = Exact<{
  input: MessageInput
}>

export type SendMessageMutation = { __typename?: 'Mutation'; sendMessage: boolean }

export type ToggleFavoriteListingMutationVariables = Exact<{
  toggleFavoriteListingInput: ToggleFavoriteListingInput
}>

export type ToggleFavoriteListingMutation = {
  __typename?: 'Mutation'
  toggleFavoriteListing: { __typename?: 'ToggleFavoriteListingResponse'; isFavorite: boolean }
}

export type ToggleFeedSubscribedMutationVariables = Exact<{
  feedId: Scalars['ID']['input']
}>

export type ToggleFeedSubscribedMutation = {
  __typename?: 'Mutation'
  toggleFeedSubscribed: { __typename?: 'ToggleFeedSubscribedResponse'; subscribed: boolean }
}

export type UpdateClientMutationVariables = Exact<{
  input: UpdateClientInput
}>

export type UpdateClientMutation = {
  __typename?: 'Mutation'
  updateClient: { __typename?: 'Client'; updatedAt?: string | null; favoriteListingsAlertCadenceDays?: number | null }
}

export type UpdateFavoriteListingMutationVariables = Exact<{
  listingId: Scalars['ID']['input']
  input: UpdateFavoriteListingInput
}>

export type UpdateFavoriteListingMutation = {
  __typename?: 'Mutation'
  updateFavoriteListing: {
    __typename?: 'FavoriteListing'
    updatedAt?: string | null
    alertCadenceDays?: number | null
    addressKey?: string | null
  }
}

export type UpdateSavedSearchAlertCadenceMutationVariables = Exact<{
  updateSavedSearchAlertCadenceId: Scalars['ID']['input']
  input: SavedSearchAlertCadenceInput
}>

export type UpdateSavedSearchAlertCadenceMutation = {
  __typename?: 'Mutation'
  updateSavedSearchAlertCadence: { __typename?: 'SavedSearch'; id: string }
}

export type CreateClientEventMutationVariables = Exact<{
  clientEventInput: ClientEventInput
}>

export type CreateClientEventMutation = { __typename?: 'Mutation'; createClientEvent: boolean }

export const ChangeFeedLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeFeedLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'googlePlaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeFeedLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feedId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'googlePlaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'googlePlaceId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedType' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ChangeFeedLocationMutation, ChangeFeedLocationMutationVariables>
export const ChangeFeedsLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeFeedsLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'googlePlaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeFeedsLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feedIds' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'googlePlaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'googlePlaceId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedType' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ChangeFeedsLocationMutation, ChangeFeedsLocationMutationVariables>
export const CreateSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearchInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>
export const DeleteSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deleteSavedSearchId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deleteSavedSearchId' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>
export const FavoriteListingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FavoriteListings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favoriteListings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unitNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mlsId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateLicenseState' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateLicenseNumber' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'alertCadenceDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageCoverUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsLogo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originatingSystemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousListPriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceChangeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertySubtype' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brokerage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftLot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showMapLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageFileNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yearBuilt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<FavoriteListingsQuery, FavoriteListingsQueryVariables>
export const FeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Feed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FeedOptions' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feedId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'feedType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastNotificationAtNative' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastNotificationAtWeb' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableRatePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closePriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listing_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openHouses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'directions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'refreshments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startTime' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'originatingSystemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousListPriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceChangeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showMapLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftLot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSellerConcession' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageFileNames' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unitNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stateAbbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<FeedQuery, FeedQueryVariables>
export const EphemeralFeedsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EphemeralFeeds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EphemeralFeedsInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ephemeralFeeds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unitNumber' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableRatePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomsCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brokerage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucketedMonthlyHomeExpenses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'homeInsurance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'monthlyHoaFees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrincipalAndInterest' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mortgageInsurance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyTaxes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalMonthlyHomeExpenses' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'closeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closePriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'complianceBrokerageInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSellerConcession' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internetAddressDisplayYn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listing_url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loanInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'downpayment' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'interestRatePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loanAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'termYears' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsLogo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originatingSystemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousListPriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceChangeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showMapLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftLot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageFileNames' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EphemeralFeedsQuery, EphemeralFeedsQueryVariables>
export const FeedsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Feeds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'googlePlaceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'webUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feeds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'googlePlaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'googlePlaceId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'webUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'webUserId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastNotificationAtNative' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastNotificationAtWeb' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stateAbbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<FeedsQuery, FeedsQueryVariables>
export const ListingEnrichedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingEnriched' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'listingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingEnrichedOptions' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingEnriched' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'listingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'listingId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableLoanType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableLoanBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableMonthlyPayment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableMonthsInHome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableNumLoanPayments' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableRatePercent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unitNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mlsId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateLicenseState' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateLicenseNumber' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'closeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closePriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasBasement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasFireplace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasGarage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPool' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSpa' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSellerConcession' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasView' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageCoverUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsLogo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openHouses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'directions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'refreshments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startTime' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'originatingSystemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousListPriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceChangeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertySubtype' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brokerage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buyerBrokerageName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftLot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internetAddressDisplayYn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showMapLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageFileNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'utilities' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yearBuilt' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'similarCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'similarListings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unitNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closePriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listing_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openHouses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'directions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'refreshments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startTime' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'originatingSystemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousListPriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceChangeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showMapLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internetAddressDisplayYn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftLot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageFileNames' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smartMove' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'monthsToReach' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsIncrease' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creditIncrease' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'debtPayOffAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isLoanRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'purchasingPower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredMonthlyHousingPayment' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'milestones' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectedBuyingPower' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buyingPowerIncrease' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCashIncrease' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creditScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creditScoreIncrease' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'debtsToRepay' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'debtsToRepayChange' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'debtsToRepayTotalCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'debtsToRepayChangeCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'estimatedMonthsToComplete' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'buyingPowerResult' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'programName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buyingPower' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'loanAmount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'downPayment' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'reserves' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'closingCosts' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'flexFunds' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalMonthlyHomeExpenses' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'interestRate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'qualifies' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'BEDTI' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'FEDTI' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'LTV' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'constrainedByDTI' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dpaProgram' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstMortgageAmount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'secondMortgageAmount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'constrainedByPreferredPayment' } }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'programName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'months' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bucketedMonthlyHomeExpenses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'homeInsurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrincipalAndInterest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mortgageInsurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyTaxes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalMonthlyHomeExpenses' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyHoaFees' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loanInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'loanAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downpayment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termYears' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestRatePercent' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allHomesTab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'averageBaths' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'averageBeds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'averageSqft' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inventoryTotal' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marketHistory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'score' } }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricedAroundTab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'averageBaths' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'averageBeds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'averageSqft' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inventoryTotal' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marketHistory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'score' } }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assumableMortgageLoanInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'downpayment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestRatePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loanAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termYears' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assumableMortgageMonthlyHomeExpenses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'homeInsurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyHoaFees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrincipalAndInterest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mortgageInsurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyTaxes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondMortgagePrincipalAndInterest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalMonthlyHomeExpenses' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assumableMortgageSecondLoanInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'downpayment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestRatePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loanAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termYears' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ListingEnrichedQuery, ListingEnrichedQueryVariables>
export const ListingsForAreaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingsForArea' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchArea' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchArea' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingsFilters' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingsSortOrder' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsForArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchArea' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchArea' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortOrder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unitNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brokerage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buyerBrokerageName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closePriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'daysOnMarket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geohash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSellerConcession' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listing_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsLogo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mlsNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucketedMonthlyHomeExpenses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'homeInsurance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrincipalAndInterest' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mortgageInsurance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyTaxes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalMonthlyHomeExpenses' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'monthlyHoaFees' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openHouses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'directions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'refreshments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startTime' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'originatingSystemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousListPriceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceChangeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showMapLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internetAddressDisplayYn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assumableRatePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftLot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sqftTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageFileNames' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loanInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'loanAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'downpayment' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'termYears' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'interestRatePercent' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stateAbbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ListingsForAreaQuery, ListingsForAreaQueryVariables>
export const PreferredAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreferredAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreferredAgentInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'agentEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PreferredAgentQuery, PreferredAgentQueryVariables>
export const UpdatePreferredAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePreferredAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePreferredAgentInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePreferredAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'agentEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdatePreferredAgentMutation, UpdatePreferredAgentMutationVariables>
export const ResetFavoriteListingsAlertCadenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetFavoriteListingsAlertCadence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetFavoriteListingsAlertCadence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favoriteListings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'alertCadenceDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressKey' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ResetFavoriteListingsAlertCadenceMutation,
  ResetFavoriteListingsAlertCadenceMutationVariables
>
export const SavedSearchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SavedSearches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearchesInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'savedSearches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alertCadenceDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasOpenHouses' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSellerConcession' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAssumable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxAcres' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSqft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxYearBuilt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minAcres' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minBathroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minBedroomsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSqft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minYearBuilt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyExpensesInput' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'downpaymentAmountDollars' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'interestRatePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyExpenses' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minMonthlyExpenses' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyTypes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuses' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastNotificationAtNative' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastNotificationAtWeb' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSeenAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchArea' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boundingBox' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bottomRight' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topLeft' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'longitude' } }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geoArea' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateAbbreviation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeIdInput' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'placeId' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sortOrder' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDir' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribedAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SavedSearchesQuery, SavedSearchesQueryVariables>
export const SendMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SendMessageMutation, SendMessageMutationVariables>
export const ToggleFavoriteListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleFavoriteListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toggleFavoriteListingInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ToggleFavoriteListingInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleFavoriteListing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toggleFavoriteListingInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toggleFavoriteListingInput' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ToggleFavoriteListingMutation, ToggleFavoriteListingMutationVariables>
export const ToggleFeedSubscribedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleFeedSubscribed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleFeedSubscribed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feedId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscribed' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ToggleFeedSubscribedMutation, ToggleFeedSubscribedMutationVariables>
export const UpdateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favoriteListingsAlertCadenceDays' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateClientMutation, UpdateClientMutationVariables>
export const UpdateFavoriteListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFavoriteListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'listingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateFavoriteListingInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFavoriteListing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'listingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'listingId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alertCadenceDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressKey' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateFavoriteListingMutation, UpdateFavoriteListingMutationVariables>
export const UpdateSavedSearchAlertCadenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSavedSearchAlertCadence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateSavedSearchAlertCadenceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearchAlertCadenceInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSavedSearchAlertCadence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateSavedSearchAlertCadenceId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateSavedSearchAlertCadenceMutation, UpdateSavedSearchAlertCadenceMutationVariables>
export const CreateClientEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientEventInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientEventInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createClientEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientEventInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientEventInput' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateClientEventMutation, CreateClientEventMutationVariables>
