import { differenceInDays, format } from 'date-fns'
import { Flex, Text, Icon } from '@homebotapp/hb-react-component-catalog'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'

export interface ListingOpenHouseTagProps {
  listing: ListingCompact | Listing
}

export const ListingOpenHouseTag = ({ listing }: ListingOpenHouseTagProps) => {
  if (!listing?.openHouses?.length) {
    return null
  }

  const futureOpenHouses = listing.openHouses.filter(
    openHouse => !!openHouse?.endTime && new Date(openHouse?.endTime) > new Date()
  )

  if (!futureOpenHouses?.length || !futureOpenHouses?.[0]?.startTime || !futureOpenHouses?.[0]?.endTime) {
    return null
  }

  const openHouseStartDay = format(new Date(futureOpenHouses[0]?.startTime), 'E')
  const openHouseEndTime = format(new Date(futureOpenHouses[0]?.endTime), 'h:mm a')
  const openHouseStartDate = format(new Date(futureOpenHouses[0]?.startTime), 'M/d')
  const openHouseStartTime = format(new Date(futureOpenHouses[0]?.startTime), 'h:mm')

  return futureOpenHouses?.length > 0 ? (
    <Flex
      px={2}
      py={1}
      ml={2}
      gap={2}
      borderWidth={1}
      borderRadius={9}
      alignItems='center'
      justifyItems='center'
      borderColor='neutral.500'
      backgroundColor='neutral.100'
    >
      <Icon name='opendoor' h={4} w={4} />
      <Text margin={0} fontWeight={600} size='2xs'>
        {differenceInDays(new Date(futureOpenHouses[0]?.startTime), new Date()) > 7
          ? openHouseStartDate
          : openHouseStartDay}{' '}
        {openHouseStartTime}
        {' - '}
        {openHouseEndTime}
      </Text>
    </Flex>
  ) : null
}
