import { useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useDispatch, useSelector } from 'react-redux'
import { defineMessages, useIntl } from 'react-intl'
import { ERRORS } from '../../../constants/messages'
import { selectRouteKey } from '../../../store/selectors/routes'
import { selectClientId, selectClientIsGuest } from '../../../store/selectors/client'
import { selectCustomerProfile } from '../../../store/selectors/customerProfile'
import { selectHomeId } from '../../../store/selectors/home'
import { selectBuyerId } from '../../../store/selectors/buyerInfo'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'
import { ListingDetailsListing } from '../../../types/listings'

import QuestionSingle from './QuestionSingle'
import { flashNotification } from '../../../actions'
import { createHomeownerMessage, createBuyerMessage } from '../../../api/mikasa/requests'
import { useTrackingContext } from '../../../providers/tracking'
import { CUSTOMER_MESSAGES, PREQUAL_TOPIC_KEYS, TopicKey } from '../../../constants/customerMessages'
import { useToast } from '@homebotapp/hb-react-component-catalog'

// See apps/clients-frontend/src/store/selectors/listings.js
type ListingMetadata = {
  customer_id: string
  email: string
  listing: ListingDetailsListing
}

interface MessageProps {
  topic: string
  topicKey: string
  message?: string
  clientId?: string
  metadata?: ListingMetadata
}

interface QuestionSingleContainerProps {
  avatarUri?: string
  className?: string
  metadata?: ListingMetadata
  name?: string
  onBlur?: (e) => void
  onFocus?: (e) => void
  onSubmit?: (status, callback) => void
  placeholder?: string
  question?: string
  questionTopic: string
  questionTopicKey: string
  setHasSentDM?: (hasSentDM: boolean) => void
  showPrequalSwitchUnderSingleQuestion?: boolean
}

const NEW_REZ_PHONE_NUMBER = '(855) 923-1054'
const NEW_REZ_DTC_CUSTOMER_PROFILE_ID = 'f2190c10-8853-4d93-8b31-2fb0fe4eda5d'
const NEW_REZ_UPDATED_DM_NOTIFICATION_TOPIC_KEYS = [
  CUSTOMER_MESSAGES.HOMEOWNER_MARKET.topicKey,
  CUSTOMER_MESSAGES.SHOULD_SELL_MARKET.topicKey,
  CUSTOMER_MESSAGES.HOME_APPRECIATION.topicKey,
  CUSTOMER_MESSAGES.IF_THEY_SHOULD_SELL.topicKey,
  CUSTOMER_MESSAGES.USING_EQUITY.topicKey,
  CUSTOMER_MESSAGES.PURCHASING_POWER.topicKey,
  CUSTOMER_MESSAGES.HOME_VALUE.topicKey
]

export const MSG = defineMessages({
  newRezSendSuccess: {
    id: 'Questions.newRez.send.success',
    defaultMessage:
      'Great question! We would be happy to review your options and answer any questions you have. Please call us at {phone} and one of our licensed representatives would be happy to assist!'
  },
  sendSuccess: {
    id: 'Questions.send.success',
    defaultMessage: 'Message sent!'
  },
  sendFailure: {
    id: 'Questions.send.failure',
    defaultMessage: 'Message failed to send'
  }
})

const QuestionSingleContainer = ({
  className,
  name,
  question,
  questionTopic,
  questionTopicKey,
  metadata,
  placeholder,
  avatarUri,
  showPrequalSwitchUnderSingleQuestion = true,
  onBlur,
  onFocus,
  onSubmit,
  setHasSentDM
}: QuestionSingleContainerProps) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const isGuest = useSelector(selectClientIsGuest)
  const isBuyer = selectRouteKey() === 'buy' || selectRouteKey() === 'homeSearch'
  const buyerId = useSelector(selectBuyerId)
  const homeId = useSelector(selectHomeId)
  const clientId = useSelector(selectClientId) as string
  const customerProfile = useSelector(selectCustomerProfile)
  const [inputValue, setInputValue] = useState(question || '')
  const [isLoading, setIsLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const intl = useIntl()
  const { trackingClient } = useTrackingContext()

  // Mark: - Helpers
  const clearInput = () => {
    setInputValue('')
  }

  const toggleSignUpForm = show => {
    dispatch(toggleAuthModal(show))
  }

  const isQuestionValid = () => {
    if (!customerProfile) {
      showFlashMessage({
        message: [intl.formatMessage(ERRORS.defaultHeadline), intl.formatMessage(ERRORS.defaultMessage)],
        messageType: 'error'
      })

      Sentry.captureMessage('Unable to send dm because customerProfile is not present')
      return false
    }

    return true
  }

  const showFlashMessage = messageParams => {
    dispatch(flashNotification(messageParams))
  }

  const reportMessageCreationError = err => {
    if (err.response != null) {
      Sentry.captureMessage('DM failed to send', {
        extra: {
          error: err.message,
          requestData: err.config.data,
          requestErrors: Object.values(err.response.data.errors)
        }
      })
    } else {
      Sentry.captureException('DM failed to send', {
        extra: {
          error: err.message
        }
      })
    }
  }
  // End: - Helpers

  // Mark: - Event handlers
  const handleChange = e => {
    setInputValue(e.target.value)
  }

  const handleFocus = e => {
    setIsFocused(true)
    onFocus && onFocus(e)
  }

  const handleBlur = e => {
    setIsFocused(false)
    onBlur && onBlur(e)
  }

  const handleTextAreaClick = e => {
    if (isGuest) {
      toggleSignUpForm(true)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const valid = isQuestionValid()

    if (!valid) {
      return null
    }

    if (isGuest) {
      toggleSignUpForm(true)
      return null
    }

    setHasSentDM?.(true)

    let messageParams: MessageProps = {
      topic: questionTopic,
      topicKey: questionTopicKey,
      message: inputValue || placeholder
    }

    setIsLoading(true)

    let recordId
    let createMessage

    if (isBuyer) {
      recordId = buyerId
      createMessage = createBuyerMessage
      messageParams = {
        ...messageParams,
        metadata
      }
    } else {
      recordId = homeId
      createMessage = createHomeownerMessage
      messageParams = {
        ...messageParams,
        clientId
      }
    }

    let responseMessage
    let isSuccess
    try {
      await createMessage(recordId, messageParams, trackingClient)
      responseMessage =
        customerProfile.id === NEW_REZ_DTC_CUSTOMER_PROFILE_ID &&
        // @ts-ignore
        NEW_REZ_UPDATED_DM_NOTIFICATION_TOPIC_KEYS.includes(questionTopicKey)
          ? intl.formatMessage(MSG.newRezSendSuccess, {
              phone: NEW_REZ_PHONE_NUMBER
            })
          : intl.formatMessage(MSG.sendSuccess)
      isSuccess = true
      return toast({
        description: responseMessage,
        status: 'success',
        position: 'top',
        duration:
          customerProfile.id === NEW_REZ_DTC_CUSTOMER_PROFILE_ID &&
          // @ts-ignore
          NEW_REZ_UPDATED_DM_NOTIFICATION_TOPIC_KEYS.includes(questionTopicKey)
            ? 10000
            : 5000
      })
    } catch (err) {
      reportMessageCreationError(err)
      responseMessage = intl.formatMessage(MSG.sendFailure)
      isSuccess = false
      return toast({
        description: responseMessage,
        status: 'error'
      })
    } finally {
      setTimeout(() => {
        setIsLoading(false)
        if (onSubmit) onSubmit(isSuccess, clearInput)
      }, 1000)
    }
  }
  // End: - Event Handlers

  const showPrequalSwitch =
    showPrequalSwitchUnderSingleQuestion && PREQUAL_TOPIC_KEYS.includes(questionTopicKey as TopicKey)

  return (
    <QuestionSingle
      avatarUri={avatarUri}
      className={className}
      inputValue={inputValue}
      isFocused={isFocused}
      isLoading={isLoading}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onSubmit={handleSubmit}
      onTextAreaClick={handleTextAreaClick}
      placeholder={placeholder}
      showPrequalSwitchUnderSingleQuestion={showPrequalSwitch}
    />
  )
}

export default QuestionSingleContainer
